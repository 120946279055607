import React from "react";
import { useLocation } from "react-router-dom";
import {
	createUseStyles
} from "react-jss";

import useSavedHomes from "hooks/useSavedHomes";

import ButtonGroup from "./ButtonGroup";
import ButtonOutline from "./ButtonOutline";
import UnitInfoLine from "./UnitInfoLine";

import ThemeData from "../data/ThemeData";
import NetData from "../data/NetData";

import { sendGoogleAnalyticsEvent } from "util/sendGoogleAnalyticsEvent";

import { BookMarkButton } from "./BookMarkButton";

const useStyles = createUseStyles(
	{
		wrapper: {
			width: 330,
			minHeight: 200,

			position: 'absolute',
			right: 100,
			top: 200,

			transition: 'all 2s',

			...ThemeData.styles.hoverCard,
		},
		hidden: {
			display: 'none',
		},
		floorplanImage: {
			minHeight: 190,

			borderBottom: `1px ${ThemeData.colours.blackBg15} solid`,
		},
		'$floorplanImage, $details': {
			borderBottom: `1px ${ThemeData.colours.eggshellTextBg} solid`,
		},
		details: {
			position: 'relative',
			display: 'flex',
			justifyContent: 'space-between',
			minHeight: 63,

			padding: 20,

			textAlign: 'left',

			'& h4': {
				margin: 0,
				padding: 0,

				fontFamily: ThemeData.fonts.title,
				fontSize: '24px',
			},

			'& p': {
			},
		},
		unitInfoLine: {
			marginTop: 10,

			color: ThemeData.colours.grey,

			fontFamily: ThemeData.fonts.copy,
			fontSize: '12px',
			letterSpacing: '0.05em',
		},
		saveHomePlus: {
			position: 'absolute',
			top: 16,
			right: 20,

			width: 16,
			height: 16,

			// background: `center no-repeat url(${saveHomePlusLight})`,
		},
		buttonArea: {
			paddingInline: 20,
			paddingBlockEnd: 20,

			borderBottom: `1px ${ThemeData.colours.blackBg15} solid`,
		},
	},
	{
		name: "UnitDetailCard",
	}
)

function renderUnitDetailCard( selectedUnitId, unit, classes, error, toggleUnitDetailPaneFcn, hooks, location, props ) {
	const doDisplayCard = !( selectedUnitId === -1 );

	return (
		<div id="unit-detail-card" className={`${classes.wrapper} ${doDisplayCard ? "" : classes.hidden}`}>
			<div className={classes.floorplanImage}>
				{unit && unit.unit_plan_type && unit.unit_plan_type.floorplan_image &&
					<img
						src={`${NetData.imgUri( unit.unit_plan_type.floorplan_image.formats && unit.unit_plan_type.floorplan_image.formats.small ?
							unit.unit_plan_type.floorplan_image.formats.small.url :
							unit.unit_plan_type.floorplan_image.url )} `}
						alt={unit.unit_plan_type.floorplan_image.alternativeText}
						width={331}
						height={187}
					/>
				}
			</div>
			<div className={classes.details}>
				<div>
					<h4>{unit && `Plan ${unit.unit_plan_type.name}`}</h4>
					<div className={classes.unitInfoLine}>{unit && <UnitInfoLine
						data={unit}
					/>}</div>
				</div>
				<BookMarkButton id="book-mark-button-unit-detail-card" hooks={hooks} dataUnit={unit} height={30} width={22}/>
			</div>
			<div className={classes.buttonArea}>
				<ButtonGroup>
					<ButtonOutline
						id="enjoy-the-views-unit-detail-card"
						label="Enjoy the View"
						textColour={ThemeData.colours.primaryAccent}
						hoverTextColour={ThemeData.colours.white}
						borderColour={ThemeData.colours.grey25}
						to="."
						onClick={props.toggleViewStudyPaneFcn}
						firstInColl

					/>
					<ButtonOutline
						id="unit-detail-card-ttt"
						label="Take the Tour"
						textColour={ThemeData.colours.primaryAccent}
						hoverTextColour={ThemeData.colours.white}
						borderColour={ThemeData.colours.grey25}
						onClick={props.toggleVirtualTourPaneFcn}
						to="."
					/>
				</ButtonGroup>
			</div>
				<ButtonOutline
					id="unit-detail-card-more-details"
					label="More Details"
					padding={20}
					textColour={ThemeData.colours.primaryAccent}
					hoverTextColour={ThemeData.colours.white}
					border="none"
					backgroundColour={ThemeData.colours.neuGray}
					onClick={() => {
						toggleUnitDetailPaneFcn();
						unit && sendGoogleAnalyticsEvent(
							`Floor Plan View - ${location.pathname}`,
							`User Clicks ${unit.unit_plan_type.name} Unit ${unit.unit_number}`,
							`${unit.unit_plan_type.name} Unit ${unit.unit_number}`,
							`${unit.unit_plan_type.name} Unit ${unit.unit_number}`
						);
					}}
				/>
		</div>
	);
}


function UnitDetailCard( { selectedUnitId, unit, toggleUnitDetailPaneFcn, ...props } ) {
	const classes = useStyles();
	const location = useLocation();
	const { savedHomes, toggleSavedHome, savedHomesIncludes } = useSavedHomes();

	let hooks = {
		savedHomes,
		toggleSavedHome,
		savedHomesIncludes
	}

	return renderUnitDetailCard( selectedUnitId, unit, classes, null, toggleUnitDetailPaneFcn, hooks, location, props );
}



export default UnitDetailCard;