import { useEffect, useState } from "react";
import { sendGoogleAnalyticsEvent } from 'util/sendGoogleAnalyticsEvent';
import { useLocation } from 'react-router-dom';

const FilterButton = ({data, hooks, ...props}) => {
    const [isActive, setActive] = useState(false);
    const location = useLocation();
    
    useEffect(() => {
        if(hooks.globalUnitsFilter.globalUnitsFilter.filter[props.filterType]
            && hooks.globalUnitsFilter.globalUnitsFilter.filter[props.filterType][props.listItemId] 
            && hooks.globalUnitsFilter.globalUnitsFilter.filter[props.filterType][props.listItemId].doFilterByOr === true) {
            setActive(true);
        } else {
            setActive(false);
        }
    },[hooks.globalUnitsFilter.globalUnitsFilter.filter, props, props.filterType, props.listItemId])

    const handleToggle = (filterType, itemId) => {
        const newFilterSettings = { ...props.filterSettings };
        if ( !newFilterSettings.hasOwnProperty( filterType ))
            newFilterSettings[filterType] = {};
        if( !newFilterSettings[filterType].hasOwnProperty( itemId ) )
            newFilterSettings[filterType][itemId] = {
                    doFilterByOr: true,
                    id: itemId,
                    value: filterType
            };
        else
            newFilterSettings[props.filterType][props.listItemId].doFilterByOr = !newFilterSettings[props.filterType][props.listItemId].doFilterByOr;
    
            hooks.globalUnitsFilter.setFilter( newFilterSettings );
    }
    return (
        <div
            id={`filter-item-${props.name.replace(/\s+/g, '')}`} 
            className={`${props.classes.filterItem} ${props.classes.unitHomeTypes} ${isActive && props.classes.filterActivated}`} 
            key={props.filterObject.id} typeitemid={props.filterObject.id} 
            onClick={
                () => {
                    handleToggle( props.filterType, parseInt(props.listItemId));
                    sendGoogleAnalyticsEvent(
                        `Filter Button Click, ${props.modelType} - ${location.pathname}`,
                        `User Clicks Filter - ${props.filterType}, ${props.name}`,
                        `Filter - ${props.filterType} - ${props.name}`,
                        `${props.filterType} - ${props.name}`
                    )
                }
            }
        >   
            <p>{props.name.toUpperCase()}</p>
        </div>
    );
}

export default FilterButton