import {
	useRouteMatch,
	Link,
} from "react-router-dom";

import {
	motion,
} from "framer-motion";

import { createUseStyles } from "react-jss";

import ThemeData from "../data/ThemeData";
import ConstantData from "data/ConstantData";
// import NetData from "../data/NetData";

import imgFunctions from "util/imgFunctions";
import richifyTextBasic from "util/richifyTextBasic";

import contentCardPlus from "../img/content_card_plus.svg";

const useStyles = createUseStyles(
	{
		contentCardItem: {
			position: 'relative',

			flex: '0 0 auto',
			display: 'flex',

			overflow: 'hidden',
			// overflow: 'clip',

			'&:hover $bgImg': {
				transform: 'scale(1.1)',
			},
			'&:hover $bgImgNoDark': {
				transform: 'none !important',
			},

			'& a, & a:visited': {
				// color: `${ThemeData.colours.eggshellTextBg}`,
				color: `${ThemeData.colours.primaryFont}`,
			},
		},
		contentCardTitleContainer: {
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
		},
		contentCardTitle: {
			flex: '0 1 auto',

			padding: 60,

			...ThemeData.typography.contentCardTitles,
		},
		contentCardPlus: {
			position: 'absolute',
			right: 0,

			width: 100,
			height: 100,

			background: 'no-repeat center',
			backgroundImage: `url('${contentCardPlus}')`
		},
		contentCardWrapper: {
			cursor: 'pointer'
		},
		oneUp: {
			height: 'calc( 50% - 10px )',
		},
		twoUpHoriz: {
			// flex: '1 1 auto',
			// width: '66%',
			width: '100%'
		},
		twoUpVert: {
			// flex: '1 1 auto',
			// width: '33%',
			height: '100%',
		},
		fourUp: {
			// flex: '1 1 auto',
			width: '100%',
			height: '100%',
		},
		sixUp: {
			width: '100%',
			height: '100%',
		},
		equalSized: {
			position: 'absolute',
			top: 0,
			right: 0,
			bottom: 0,
			left: 0,
		},
		bgImg: {
			background: `no-repeat center`,
			backgroundSize: 'cover',

			opacity: .5,

			transition: 'all .4s ease',
		},
		bgImgNoDark: {
			opacity: 1,

			backgroundSize: 'contain',
		},
		bgImgBlackBg: {
			backgroundColor: 'black',
		},
		bgImgNoBg: {
			backgroundColor:'rgba(0,0,0,0)'
		},
		imgCard: {
			width: '100%',
			height: '100%',
			objectFit: 'contain',
		},
	},
	{
		name: "ContentCard"
	}
);

function ContentCard( { contentPage, tab, setTab } ) {

	const classes = useStyles();
	const matchUri = useRouteMatch();

	let contentPageUrl = `${matchUri.url}/${contentPage.slug}`;

	if ( contentPage.custom_link ) {
		contentPageUrl = `${contentPage.custom_link}`;
	}

	// Figure out if the Card should be dark with a title over it or not
	let doNotDarkenOrTitleCard = false;
	if ( contentPage.section.slug === "gallery" )
		doNotDarkenOrTitleCard = true;
	if ( contentPage.is_only_image_card )
		doNotDarkenOrTitleCard = true;

	const renderCardsWithLinks = (section) => {
		if(section.slug === 'about' || section.slug === 'neighbourhood') {
			return (
				<div className={classes.contentCardWrapper}>
					<div onClick={() => setTab(contentPage.order)}>
						<div className={`${classes.equalSized} ${classes.contentCardTitleContainer}`}>
							<motion.div 
								initial={{opacity: 0}}
								animate={{opacity: 1}}
								transition={{duration: 0.5, delay: 0.5}}
								exit={{opacity: 0, transition:{duration: 0.25, delay: 0}}}
								className={classes.contentCardTitle}>
								<p dangerouslySetInnerHTML={{
									__html: doNotDarkenOrTitleCard ?
										"" :
										richifyTextBasic( contentPage.card_title ?
											contentPage.card_title :
											contentPage.title )
								}} />
							</motion.div>
						</div>
					</div>
					<motion.div 
						initial={{opacity: 0}}
						animate={{opacity: 1}}
						transition={{duration: 0.5, delay: 0.5}}
						exit={{opacity: 0, transition:{duration: 0.25, delay: 0}}}
						className={classes.contentCardPlus}/>
				</div>
			)
		} else {
			return (
				<Link to={contentPageUrl + "/"}>
					<div className={`${classes.equalSized} ${classes.contentCardTitleContainer}`}>
						<motion.div 
							initial={{opacity: 0}}
							animate={{opacity: 1}}
							transition={{duration: 0.5, delay: 0.5}}
							exit={{opacity: 0, transition:{duration: 0.25, delay: 0}}}
							className={classes.contentCardTitle}>
							<p dangerouslySetInnerHTML={{
								__html: doNotDarkenOrTitleCard ?
									"" :
									richifyTextBasic( contentPage.card_title ?
										contentPage.card_title :
										contentPage.title )
							}} />
						</motion.div>
					</div>
					<motion.div 
						initial={{opacity: 0}}
						animate={{opacity: 1}}
						transition={{duration: 0.5, delay: 0.5}}
						exit={{opacity: 0, transition:{duration: 0.25, delay: 0}}}
						className={classes.contentCardPlus}/>
				</Link>
			)
		}
	}

	const renderContent = () => {

		return (
			<motion.div id={contentPage.slug} className={`${contentPage.form && classes[contentPage.form.value]} ${classes.contentCardItem}`} key={contentPage.id}
				initial={{width: 0 }}
				animate={{width: "100%" }}
				transition={{duration: 0.75}}
			>
				{contentPage.image[0] && contentPage.image[0].url.includes( '.mp4' ) ?
					<motion.div
						exit={{x:window.innerWidth, transition:{ type: 'linear', duration: .5 } } }
					>
						<video
							style={{ width: '100%', objectFit: 'cover' }}
							// controls
							autoPlay
							muted
							loop
						>
							<source src={contentPage.image[0].url} type="video/mp4" />
						</video>
					</motion.div>
					:
					<>
						<motion.div 
							className={`${classes.equalSized} ${contentPage.is_only_image_card ? classes.bgImgNoBg : classes.bgImgBlackBg}`}
							exit={{x:window.innerWidth, transition:{ type: 'linear', duration: 0.75 } } }
							>
							<motion.div
								className={`${classes.equalSized} ${classes.bgImg} ${doNotDarkenOrTitleCard ? classes.bgImgNoDark : classes.bgImgDark}`}
								style={!contentPage.is_only_image_card ? contentPage.image[0] && {
									backgroundImage: `url(${imgFunctions.GetImgBySizeUri( contentPage.image[0], ConstantData.imgSizes.large )})`
								} : null}
							>
								{contentPage.is_only_image_card &&
									<img
										className={classes.imgCard}
										alt={contentPage.image[0].alternativeText}
										src={imgFunctions.GetImgBySizeUri( contentPage.image[0], ConstantData.imgSizes.large )}
										width={imgFunctions.GetImgBySizeWidth( contentPage.image[0], ConstantData.imgSizes.large )}
										height={imgFunctions.GetImgBySizeHeight( contentPage.image[0], ConstantData.imgSizes.large )}
									/>
								}
							</motion.div>
						</motion.div>
						{contentPage.is_only_image_card ?
							<></> :
							renderCardsWithLinks(contentPage.section)
						}
					</>
				}
			</motion.div>
		)
	}

	

	return (
		renderContent()
	)
}


export default ContentCard;