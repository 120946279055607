import React, {
	useState,
	useEffect,
} from "react";

import { 
	useLocation,
} from 'react-router-dom';

import {
	createUseStyles,
} from "react-jss";

import {
	useQuery,
} from "@apollo/client";

import {
	motion,
	AnimatePresence,
} from "framer-motion";

import { ReactComponent as ListView } from '../img/prox_units-list-view.svg';
import { ReactComponent as GridView } from '../img/prox_units-grid-view.svg';

import ThemeData from "data/ThemeData";
import ConstantData from "data/ConstantData";
import QueryData from "data/QueryData";
import SiteConfigData from "data/SiteConfigData";


import useSavedHomes from "hooks/useSavedHomes";
import useGlobalUnitsFilter from "hooks/useGlobalUnitsFilter";
import useSiteFeatureToggles from "hooks/useSiteFeatureToggles";
import FilterHeader from "./FilterHeader";

const useStyles = createUseStyles(
	{
		wrapper: {
			height: '100%',
			'--filterHeaderHeight': '70px',
		},
		filterHeader: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between',
			height: 'var( --filterHeaderHeight )',
			boxShadow:' 0.5px 0.5px 0px 0.5px rgba(0, 0, 0, 0.05)'
		},
		filterCategoryControls: {
			flex: '1 0 auto',
		},
		filterButtons: {
			height: 50,

			flex: '0 0 auto',

			padding: '0 50px',
		},
		filterButton: {
			...ThemeData.styles.roundedCorners,
		},
		filterViewControls: {
			flex: '0 0 auto',
			height: 50,
			display: 'flex',
			alignItems: 'center',

			padding: '0 2% 0 0',

			fontSize: 32,
			color: ThemeData.colours.primaryFontHighestContrast,

			'& > div': {
				flex: '0 0 auto',
				display: 'inline-block',
				marginLeft: 20,
			},
		},
		filterViewIcon: {
			cursor: 'pointer',
			color: '#49656B',
		},
		filterViewIconSelected: {
			color: ThemeData.colours.primaryAccent,
			cursor: 'default',
		},
		filterList: {
			color: ThemeData.colours.primaryFont,
		},
		filterRange: {
			color: ThemeData.colours.secondaryFont,
			backgroundColor: ThemeData.colours.secondaryBg,
		},
		wrapperSizing: {
			height: 'calc( 100% - var( --filterHeaderHeight ) )',
		},
		paneSizing: {
			height: '100%',
		},
		fullItemsWrapper: {
			height: '100%',
			overflowY: 'auto',
			...ThemeData.styles.thinScrollbarLightOnDark,

			'&.noFilterSort': {
				maxHeight: 'calc( 100% - 50px )',
			},
		},
		dualPaneWrapper: {
			display: 'flex',
		},
		dualPanePane: {
			width: '50%',
			boxSizing: 'border-box',
		},
		dualPaneLeftPane: {
			height: '100%',
		},
		unitCards: {
			display: 'flex',
			flexWrap: 'wrap',
			justifyContent: 'space-evenly',
			boxSizing: 'border-box',

			paddingTop: 20,
		},
		bgAmp: {
			zIndex: -1,
			position: 'absolute',
			left: -50,
			bottom: -100,

			color: ThemeData.colours.primaryAccent08,
			fontFamily: ThemeData.fonts.copy,
			fontSize: 500,
		},
		contentContainer: {
			display: 'flex',
			flexDirection: 'column',

			width: '100%',
			height: '100%',
		},
		topRightContainer: {
			position: 'absolute',
			top: 60,
			right: 60,

			display: 'flex',
			alignItems: 'center',
		},
		closeX: {
			marginTop: 10,
			marginLeft: 50,

			cursor: 'pointer',
		},
		sendToEmailButton: {
			display: 'inline-block',

			width: 150,

			fontSize: 12,
			letterSpacing: '.1em',
		},
		savedHomesTitle: {
			textAlign: 'left',

			fontSize: 24,
			letterSpacing: '.15em',
		},
		top: {
			flex: '0 0 auto',
		},
		addHomesButton: {
			width: 220,

			fontSize: 12,
			letterSpacing: '.1em',

			borderRadius: 25,
		},
		middle: {
			flex: '1 0 auto',
			display: 'flex',
			flexWrap: 'wrap',
			justifyContent: 'space-evenly',

			maxHeight: 'calc( 100vh )',

			overflowY: 'auto',

			...ThemeData.styles.thinScrollbar,
		},
		unitDetailCompareCardWrapper: {
			flex: '0 0 auto',
			padding: '0 10px 40px 20px',
		},
		unitCard: {
			color: ThemeData.colours.black,
		},
		tableHeaderRow: {
			height: 50,
			display: 'flex',
			alignItems: 'center',

			color: ThemeData.colours.primaryFont,
			fontFamily: ThemeData.fonts.title,
		},
		tableHeaderItem: {
			flex: '1 0 auto',
			width: 100,
		},

		bottom: {
			flex: '0 0 auto',
			display: 'flex',

			boxSizing: 'border-box',
			paddingTop: 25,

			height: 130,

			borderTop: `1px ${ThemeData.colours.white50} solid`,
		},
		filterProducesNoResultsNotice: {
			marginTop: 10,
			color: ThemeData.colours.black
		},
		filteredResultsHeading: {
			color: ThemeData.colours.neuDeepGrey,
			marginLeft: 24
		},
		disclaimer: {
			color: 'rgba(0, 0, 0, 0.75)',
			textAlign: 'center',
			marginLeft: 20,
			fontStyle: 'italic',
			fontWeight: 100,
		},
		icon: {
			cursor: 'pointer'
		}
	},
	{
		name: "UnitCardsParent",
	},
)

const RenderUnitCardsParent = ( classes, data, filteredUnitsData, error, isOpen, toggleIsOpen, hooks, props ) => {

	const location = useLocation();
	
	if ( error ) {
		console.log( "RenderFloorplans", error.message );
	}
	return (
		<>
		<div className={classes.wrapper}>
			{( !props.dontShowFilter && !props.dontShowFilterSort ) &&
				<div className={classes.filterHeader}>
					<p className={classes.filteredResultsHeading}>Showing {filteredUnitsData.length} homes</p>
					{!location.pathname.includes('/proximation') && <FilterHeader doShowFilter={props.doShowFilter} setDoShowFilter={props.setDoShowFilter} />}
					<div className={classes.filterViewControls}>
						<div className={classes.controlIconGrid}
							id="unit-detail-card-view-toggle"
						>
							<GridView
								className={classes.icon}
								id="unit-detail-card-view-toggle"
								onClick={() => props.setUnitFilterViewMode( ConstantData.unitFilterViewModes.card )}
								fill={props.unitFilterViewMode === ConstantData.unitFilterViewModes.card ? ThemeData.colours.primaryAccent : ThemeData.colours.neuDarkBlueFade}
							/>
						</div>
						<div className={classes.controlIconList}
							id="unit-detail-list-view-toggle"
						>
							<ListView
								className={classes.icon} 
								id="unit-detail-list-view-toggle"
								onClick={() => props.setUnitFilterViewMode( ConstantData.unitFilterViewModes.list )}
								fill={props.unitFilterViewMode === ConstantData.unitFilterViewModes.list ? ThemeData.colours.primaryAccent : ThemeData.colours.neuDarkBlueFade}
							/>
						</div>
					</div>
				</div>
			}


			<div className={
				`${classes.wrapperSizing
				} ${props.dualPaneLeftPane && classes.dualPaneWrapper
				}`}
			>
				<AnimatePresence
				>
					{props.dualPaneLeftPane &&
						<motion.div
							className={
								`${props.dualPaneLeftPane && classes.dualPanePane
								}`}
							key={1}
							{...ThemeData.anim.defaultOpacityFade}
						>
							{props.dualPaneLeftPane}
						</motion.div>
					}
					<motion.div
						className={
							`${classes.paneSizing
							} ${props.dualPaneLeftPane && classes.dualPanePane
							}`}
						key={2}
						{...ThemeData.anim.defaultOpacityFade}
					>
						<AnimatePresence exitBeforeEnter={true}>
							{props.dualPaneRightPane ?
								props.dualPaneRightPane
								:
								<motion.div
									className={
										`${classes.fullItemsWrapper
										} ${props.fullItemsWrapperClassName
										} ${( !props.dontShowFilter && !props.dontShowFilterSort ) ? '' : 'noFilterSort'
										}`}
									{...ThemeData.anim.defaultOpacityFade}
								>
									<div
										id='unitItemsDirectParent'
										className={
											`${classes.unitCards
											} ${props.childWrapperClassName
											}`}
									>
										<AnimatePresence>
											{props.children && data && data.units && filteredUnitsData &&
												(
													[...filteredUnitsData]
														.sort( SiteConfigData.Floorplans.orderByPredicate )
														.map( ( dataUnit, idx ) => (
															<motion.div
																id={`unit-card-${idx}`}
																unitid={dataUnit.custom_id}
																className={
																	`${props.childClassName || classes.unitDetailCompareCardWrapper
																	}`}
																key={dataUnit.id}
																custom={dataUnit.id}
																{...ThemeData.anim.defaultOpacityFade}
																layout="position"
															>
																<div
																	unitid={dataUnit.custom_id}
																/>

													
																{props.children(
																	classes.unitCard,
																	dataUnit,
																	idx,
																	idx === 0,
																	() => hooks.toggleSavedHome( dataUnit.id )
																)}
															</motion.div>
														) )
												)
											}
											{location.pathname.includes('/proximation') &&
											props.children && data && data.units && filteredUnitsData && filteredUnitsData.length === 0 &&
												<motion.div
													className={classes.filterProducesNoResultsNotice}
													key={-1}
													layout="position"
												>
													No homes found with the selected filters.
												</motion.div>
											}
										</AnimatePresence>
									</div>
									<p className={classes.disclaimer}>{ConstantData.disclaimerData.disclaimer_2}</p>
									<p className={classes.disclaimer}>{ConstantData.disclaimerData.disclaimer_3}</p>
								</motion.div>
							}
						</AnimatePresence>
					</motion.div>
				</AnimatePresence>
			</div>
		</div >
		</>
	)
}



const UnitCardsParent = ( { isOpen, toggleIsOpen, ...props } ) => {

	const classes = useStyles();


	const { savedHomes, toggleSavedHome } = useSavedHomes();

	const globalUnitsFilter = useGlobalUnitsFilter();

	const siteFeatureToggles = useSiteFeatureToggles();


	let hooks = {
		savedHomes,
		toggleSavedHome,
		globalUnitsFilter,
		siteFeatureToggles,
	};



	const { loading, error, data } = useQuery( QueryData.unitProperties );


	const [filteredUnitsData, setFilteredUnitsData] = useState( [] );


	useEffect( () => {
	}, [props.selectedUnitId, props.setSelectedUnitId] )


	useEffect( () => {

		if ( data ) {
			setFilteredUnitsData( globalUnitsFilter.applyFilter( data.units ) );
		}
	}, [globalUnitsFilter.globalUnitsFilter, data] );

	useEffect( () => {
		if ( props.doSelectFirstItem ) {
			if (
				!props.selectedUnitId ||
				( filteredUnitsData.length > 0 &&
				filteredUnitsData.filter( unitData => unitData.custom_id === props.selectedUnitId ).length === 0 )
			) {
				props.setSelectedUnitId( filteredUnitsData[0].custom_id );
				return;
			}

		}
	} );

	useEffect( () => {
		if ( props.setDataUnitCustomId1 ) {
			
			if ( data && props.compareUnitCustomId1 ) {
				props.setDataUnitCustomId1( data.units.filter( dataUnit => dataUnit.custom_id === props.compareUnitCustomId1 )[0] );
				return;
			}

			props.setDataUnitCustomId1( null );
		}
	}, [props.compareUnitCustomId1, data] );
	useEffect( () => {
		if ( props.setDataUnitCustomId2 ) {
			if ( data && props.compareUnitCustomId2 ) {
				props.setDataUnitCustomId2( data.units.filter( dataUnit => dataUnit.custom_id === props.compareUnitCustomId2 )[0] );
				return;
			}

			props.setDataUnitCustomId2( null );
		}
	}, [props.compareUnitCustomId2, data] );


	/** 
	 * - Scroll Unit card selected in WebGL into view if not already in view
	 * - Animate card
	 */
	useEffect( () => {
		if ( props.setSelectedUnitData )
			props.setSelectedUnitData( filteredUnitsData.filter( unitData => unitData.custom_id === props.selectedUnitId )[0] );

		if ( props.selectedUnitId && props.selectedUnitId !== -1 ) {
			// scroll item into view

			let directParentElm = document.getElementById( 'unitItemsDirectParent' );
			if ( !directParentElm ) return;

			let unitItemElm = directParentElm.querySelector( `[unitId='${props.selectedUnitId}']` );
			if ( !unitItemElm ) return;

			unitItemElm.parentElement.scrollIntoView( {
				behavior: 'smooth',
				block: 'nearest',
			} );
		}
	}, [props.selectedUnitId] );


	if ( loading ) return RenderUnitCardsParent( classes, null, filteredUnitsData, null, isOpen, toggleIsOpen, hooks, props );
	if ( error ) return RenderUnitCardsParent( classes, null, filteredUnitsData, error, isOpen, toggleIsOpen, hooks, props );

	return RenderUnitCardsParent( classes, data, filteredUnitsData, null, isOpen, toggleIsOpen, hooks, props );

}



UnitCardsParent.defaultProps = {
	dontShowFilterSort: false,
	dontShowFilter: false,
	dontShowSort: false,
	doSelectFirstItem: false,
}



export default UnitCardsParent;