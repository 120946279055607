import React, {
	useState,
	useEffect,
	useRef,
} from "react";

import {
	createUseStyles,
} from "react-jss";

import {
	useQuery,
} from "@apollo/client";


import ThemeData from "data/ThemeData";
import QueryData from "data/QueryData";

import useSavedHomes from "hooks/useSavedHomes";
import FloorplansGeneric from "./FloorplansGeneric";



const jsStyles = {
	contentContainer: {
		flexDirection: 'column',
		position: 'relative',
		width: props => props.isOpen ? '40%' : 0,
		height: 'calc(100vh - 180px)',
		transition: 'width 0.5s',
		boxShadow: '0.5px 0.5px 0px 0.5px rgb(0 0 0 / 5%)',
		borderRight: ThemeData.colours.paneBordersLight
	},
	wrapper: {
		top: 100,
		left: 0,
		display: 'none',
		width: '50%',
		height: "100%",
		boxSizing: 'border-box',
		paddingTop: 30,
		color: ThemeData.colours.white,
		backgroundColor: ThemeData.colours.primaryBg,
		letterSpacing: '.025em',
	},
	closeX: {
		position: 'absolute',
		top: 10,
		display: 'flex',
		alignItems: 'center',
		height: 50,
		width: props => props.isOpen ? 50 : 165,
		backgroundColor: ThemeData.colours.white,
		boxShadow: '0px 4px 10px rgb(0 0 0 / 33%)',
		cursor: 'pointer',
		margin: '0 50px',
		zIndex: 1,
		borderRadius: 5,
	},
	openIcon: {
		flex: '0 0 auto',
		display: 'flex',
	},
	openText: {
		flex: '0 0 auto',
		paddingRight: 20,
		color: ThemeData.colours.secondaryAccent,
		fontSize: 12,
		textTransform: 'uppercase',
		letterSpacing: 4,
	},
	savedHomesTitle: {
		textAlign: 'left',
	},
	top: {
		flex: '0 0 auto',
		position: 'absolute',
		left: props => props.isOpen ? '36%' : 0,
		transition: 'left 0.5s',
	},
	middle: {
		flex: '1 0 auto',
		height: '100%',
		overflow: 'hidden',
	},
	bottom: {
		flex: '0 0 auto',

		boxSizing: 'border-box',
		paddingTop: 25,

		height: 130,

		borderTop: `1px ${ThemeData.colours.white50} solid`,
	},
	bottomButton: {
		fontSize: 14,
		letterSpacing: '.1em',
	},
};
const useStyles = createUseStyles(
	jsStyles,
	{
		name: "UnitCardsSlideout",
	},
)




const RenderUnitCardSlideout = ( classes, data, error, isOpen, toggleIsOpen, hooks, props, slideoutRef ) => {

	if ( error ) {
		console.log( "RenderUnitCardSlideout", error.message );
	}

	return (
		<>
			<div 
				className={classes.contentContainer} 
				ref={slideoutRef} 
			>
				<div className={classes.middle} id="unitcard-slideout-container">
					<FloorplansGeneric
						isToAllowCompare={false}
						isToShowDualPaneForTableDisplay={false}
						isToAlwaysAllowSelection={true}
						selectedUnitId={props.selectedUnitId}
						setSelectedUnitId={props.setSelectedUnitId}
						selectedUnitData={hooks.selectedUnitData}
						setSelectedUnitData={hooks.setSelectedUnitData}
						toggleIsViewStudySlideUpOpenFcn={props.toggleIsViewStudySlideUpOpenFcn}
						isToUseEmbeddedVirtualTour={false}
						toggleIsVirtualTourOpen={props.toggleIsVirtualTourOpen}
						toggleUnitDetailPane={props.toggleUnitDetailPane}

						cardOnClickLeftButton={props.cardOnClickLeftButton}
					/>
				</div>
			</div>
		</>
	)
}



const UnitCardSlideout = ( { isOpen, toggleIsOpen, ...props } ) => {

	const slideoutRef = useRef();
	
	const classes = useStyles({isOpen, slideoutRef});


	const { savedHomes, toggleSavedHome } = useSavedHomes();

	const [selectedUnitData, setSelectedUnitData] = useState( null );

	let hooks = {
		savedHomes,
		toggleSavedHome,
		selectedUnitData,
		setSelectedUnitData,
	};


	useEffect( () => {
	}, [props.selectedUnitId] )

	const { loading, error, data } = useQuery( QueryData.units );

	if ( loading ) return RenderUnitCardSlideout( classes, null, null, isOpen, toggleIsOpen, hooks, props );
	if ( error ) return RenderUnitCardSlideout( classes, null, error, isOpen, toggleIsOpen, hooks, props );

	return RenderUnitCardSlideout( classes, data, null, isOpen, toggleIsOpen, hooks, props, slideoutRef );

}



export default UnitCardSlideout;