// import useRegistration from "hooks/useRegistration";



const useSendEmail = () => {
	return {
		sendSavedHomesEmail: ( name, email, savedHomesData ) => {
			let xhr = new XMLHttpRequest();
			xhr.open( 'POST', '/api/email/saved-homes' );
			xhr.setRequestHeader( 'CONTENT-TYPE', 'application/json' );
			xhr.send( 
				JSON.stringify(
					{
						name: name,
						email: email,
						savedHomesData: savedHomesData
					}
				)
			)
		},
		sendPdfEmail: async (name, email, pdfFile, emailCopy) => {
			let xhr = new XMLHttpRequest();
			xhr.open( 'POST', '/api/email/send-pdf' );
			xhr.setRequestHeader( 'CONTENT-TYPE', 'application/json' );
			xhr.send( 
				JSON.stringify(
					{
						name: name,
						email: email,
						pdfData: pdfFile,
						emailCopy: emailCopy
					}
				)
			) 
		}
	}
}



export default useSendEmail;