import ThemeData from 'data/ThemeData';
import ButtonGroup from './ButtonGroup';
import ButtonOutline from './ButtonOutline';

const DistanceFilters = ({distance, toggleDistance}) => {
    const setFilter = (distanceInput) => {
        const tempDis = [...distance]
        if(distance.includes(distanceInput)) {
            toggleDistance(distance.filter(i => i !== distanceInput))
        } else {
            tempDis.push(distanceInput)
            toggleDistance(tempDis)
        }
    }

    return (
        <ButtonGroup
            marginBottom={15}
        >
            <ButtonOutline
                className="close-distance-button"
                firstInColl  
                isActive={distance.includes('fiveMinutes')} 
                onClick={() => setFilter('fiveMinutes')}
                backgroundColour={"transparent"}
                hoverBackgroundColour={ThemeData.colours.primaryAccentFade}
                textColour={ThemeData.colours.secondaryFont}
                hoverTextColour={ThemeData.colours.secondaryFont}
                isRounded
                margin={'0 10px 0 0'}
                height={10}

            >
                5 mins
            </ButtonOutline>
            <ButtonOutline
                className="medium-distance-button"  
                isActive={distance.includes('tenMinutes')} 
                onClick={() => setFilter('tenMinutes')}
                backgroundColour={"transparent"}
                hoverBackgroundColour={ThemeData.colours.primaryAccentFade}
                textColour={ThemeData.colours.secondaryFont}
                hoverTextColour={ThemeData.colours.secondaryFont}
                isRounded
                margin={'0 10px 0 0'}
                height={10}

            >
                10 mins
            </ButtonOutline>
            <ButtonOutline
                className="far-distance-button"  
                isActive={distance.includes('fifteenMinutes')} 
                onClick={() => setFilter('fifteenMinutes')}
                backgroundColour={"transparent"}
                hoverBackgroundColour={ThemeData.colours.primaryAccentFade}
                textColour={ThemeData.colours.secondaryFont}
                hoverTextColour={ThemeData.colours.secondaryFont}
                isRounded
                margin={'0 10px 0 0'}
                height={10}

            >
                15 mins
            </ButtonOutline>
        </ButtonGroup>
    )
}

export default DistanceFilters;