import React, {
	useEffect,
	useState,
} from "react";

import {
	useQuery,
} from "@apollo/client";

import {
	createUseStyles,
} from "react-jss";

import {
	motion,
	AnimatePresence,
} from "framer-motion";


import arrowLineRight from "../img/arrow_line_right.svg";


import ThemeData from "../data/ThemeData";
import QueryData from "data/QueryData";

import Box from '@mui/material/Box';
import ButtonOutline from "./ButtonOutline";
import useGlobalUnitsFilter from "hooks/useGlobalUnitsFilter";
import InputRangeSlider from "./InputRangeSlider";
import FilterButton from "./FilterButton";
import { filterableSources } from "data/FilterData";

import crossIcon from '../img/cross_icon.svg';
import { InputAdornment } from "@mui/material";

const useStyles = createUseStyles(
	{
        modalContainer: {
            position: 'fixed',
            backgroundColor: 'rgba(0,0,0,0.8)',
            width: '100%',
            height: '100vh',
			top: 0,
            display: 'flex',
            color: 'black',
			overflow: 'hidden',
            zIndex: 9999,
            justifyContent: 'center'
        },
		borderWrapper: {
            maxWidth: 800,
			// cursor: 'grab',
			// maxHeight: 800,
			width: '100%',
			margin: '50px 0',
			
			// border: '1px white solid',

			background: 'rgba( 0, 0, 0, 0 )',
			'&::hover':{
				background: 'black'
			}
		},
		wrapper: {
			height: '100%',
			textAlign: 'left',
			background: ThemeData.colours.white,
			boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.33)',
			borderRadius: '5px',
			overflow: 'hidden'
		},
		header: {
			display: 'flex',
			width: '100%',
			height: 60,
			border: '1px solid #EEEDE6',
			alignItems: 'center',
			'& > img': {
				alignSelf: 'flex-end',
				height: 14,
				width: 14,
				marginRight: 28,
				margin: 'auto 0',
				cursor: 'pointer'
			},
			'& > div': {
				alignSelf: 'center',
				flexGrow: '3',
				textAlign: 'center',
				paddingLeft: 42
			}
		},
		
		showHideWrapper: {
			padding: '20px 40px',
			height: 'calc(100% - 160px)',
			overflow: 'scroll',
			'&::-webkit-scrollbar':{
				display: 'none'
			},
			scrollbarWidth: 'none',
		},
		expandRetractButtonWrapper: {
			position: 'absolute',
			top: 0,
			right: 0,
		},
		expandRetractButton: {
			width: 75,
			height: 75,

			background: 'no-repeat center',
			backgroundImage: `url( ${arrowLineRight} )`,

			'&.isToExpand': {
				width: 110,
			},
		},
		filterTitle: {
			position: 'absolute',
			// display: 'none',

			marginTop: 39,

			textTransform: 'uppercase',

			'& h4': {
				margin: 0,
			},
		},
		
		paneTitle: {
			width: 250,

			'& h3': {
				margin: 0,

				fontFamily: ThemeData.fonts.titleSub,
				fontSize: '24px',
				lineHeight: '32px',
				fontWeight: '350',
				letterSpacing: '.05em',

				textTransform: 'uppercase',
			},
		},
		viewTypeButtons: {

		},
		typeTitle: {
			textTransform: 'uppercase',
			fontWeight: 700
		},
		filterBy: {

			fontFamily: ThemeData.fonts.copy,
			fontSize: 16,
			letterSpacing: '.05em',

			'& input': {
				display: 'inline-block',

				margin: 10,
			},
		},
		filterRange: {
			'& > div':{
				width: '100%'
			},
			'&.inputBox':{
				height: 53
			}
		},
		filterActivated: {
			backgroundColor: ThemeData.colours.primaryAccentFade,
			color: ThemeData.colours.primaryAccent
		},
		filterSection: {
			marginTop: 30,
			borderBottom: '1px solid #EEEDE6',
			paddingBottom: 20
		},
		filterButtons: {
			display: 'flex',
			flexWrap: 'wrap',
			'& > div': {
				flex: '0 1 auto',
			},
		},
		filterSlider: {
			display: 'flex',
			flexWrap: 'wrap',
			justifyContent: 'center',
			'& > div': {
				flex: '0 1 auto',
				width: '100%'
			}
		},
		filterItem: {
			minWidth: 30,
			height: 50,
			margin: 5,
			padding: '0 10px',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			border: `1px #EEEDE6 solid`,
			borderRadius: 4,
			transition: 'background .25s ease-out',
			cursor: 'pointer',
			'&:hover': {
				backgroundColor: ThemeData.colours.primaryAccentFade,
				color: ThemeData.colours.primaryAccent
			}
		},
		enjoyViewButtonWrapper: {
			display: 'table',
		},
		unitFloors: {

		},
		unitHomeTypes: {

		},
		unitPlanTypes: {

		},
	},
	{
		name: "FilterModal",
	}
);

function UnitFilterRender( classes, data, doShowFilter, setDoShowFilter, modelType, hooks) {
	
	const [isClearSlider, setClearSlider] = useState(false);

	useEffect( () => {
		setClearSlider(false)
	}, [isClearSlider])

	return doShowFilter && (
        <div className={classes.modalContainer}>
		 <motion.div className={classes.borderWrapper}>
			<div className={classes.wrapper}>
				<AnimatePresence>
						<div className={classes.header}>
							<div>FILTERS</div>
							<img onClick={() => setDoShowFilter(false)} src={crossIcon} alt="close" />
						</div>
						<div className={classes.showHideWrapper}
						>
							{filterableSources.availability && <div className={`${classes.filterSection} ${classes.homeTypeWrapper} `}>
								<div className={classes.typeTitle}>
									<p>Availability</p>
								</div>
								<div className={classes.filterButtons}>
								{
									data && data.unitAvailabilityStates && data.unitAvailabilityStates.map( (availabilityState ) => (
										<FilterButton
											classes={classes}
											name={availabilityState.name}
											filterObject={availabilityState}
											data={data}
											hooks={hooks}
											listItemId={availabilityState.id}
											filterType={filterableSources.availability.unitRelationDataType}
											filterSettings={hooks.globalUnitsFilter.globalUnitsFilter.filter}
											modelType={modelType}
										/>
										)
									) 
								}

								</div>
							</div>}
							{filterableSources.price && <div className={`${classes.filterSection} ${classes.orientationTypeWrapper}`}>
								<div className={classes.typeTitle}>
									<p>Price</p>
								</div>
								<div className={classes.filterSlider}>
									<InputRangeSlider 
										className={classes.filterRange}
										label={filterableSources.price.label}
										filterType={filterableSources.price.unitField}
										min={filterableSources.price.rangeMinimumValue}
										max={filterableSources.price.rangeMaximumValue}
										step={filterableSources.price.rangeStep}
										displayedDataFormattingFunction={filterableSources.price.displayedDataFormattingFunction}
										filterSettings={hooks.globalUnitsFilter.globalUnitsFilter.filter}
										setFilterSettings={hooks.globalUnitsFilter.setFilter}
										modelType={modelType}
										adornment={<InputAdornment>$</InputAdornment>}
										inputLabels={['min', 'max']}
										isClear={isClearSlider}
									/>
								</div>
							</div>}
							<div className={`${classes.filterSection} ${classes.homeTypeWrapper} `}>
								<div className={classes.typeTitle}>
									<p>ROOMS</p>
								</div>
								<div className={classes.filterButtons}>
								{data && data.unitHomeTypes && data.unitHomeTypes.map( ( homeType ) => (
									<FilterButton
										classes={classes}
										name={homeType.name}
										filterObject={homeType}
										data={data}
										hooks={hooks}
									    listItemId={homeType.id}
										filterType={filterableSources.rooms.unitRelationDataType}
										filterSettings={hooks.globalUnitsFilter.globalUnitsFilter.filter}
										modelType={modelType}
									/>
								))}
								</div>
							</div>
							<div className={`${classes.filterSection} ${classes.orientationTypeWrapper}`}>
								<div className={classes.typeTitle}>
									<p>Size</p>
								</div>
								<div className={classes.filterSlider}>
									<InputRangeSlider 
										className={classes.filterRange}
										label={filterableSources.size.label}
										filterType={filterableSources.size.unitField}
										min={filterableSources.size.rangeMinimumValue}
										max={filterableSources.size.rangeMaximumValue}
										step={filterableSources.size.rangeStep}
										displayedDataFormattingFunction={filterableSources.size.displayedDataFormattingFunction}
										filterSettings={hooks.globalUnitsFilter.globalUnitsFilter.filter}
										setFilterSettings={hooks.globalUnitsFilter.setFilter}
										modelType={modelType}
										inputLabels={['min', 'max']}
										isClear={isClearSlider}
									/>
								</div>
							</div>
							<div className={`${classes.filterSection} ${classes.orientationTypeWrapper}`}>
								<div className={classes.typeTitle}>
									<p>Orientation</p>
								</div>
								<div className={classes.filterButtons}>
								{data && data.unitExposures && data.unitExposures.map( ( exposure ) => (
									<FilterButton
										classes={classes}
										name={exposure.direction}
										filterObject={exposure}
										data={data}
										hooks={hooks}
										listItemId={exposure.id}
										filterType={filterableSources.orientation.unitRelationDataType}
										filterSettings={hooks.globalUnitsFilter.globalUnitsFilter.filter}
										modelType={modelType}
									/>
								))}
								</div>
							</div>
							<div className={`${classes.filterSection} ${classes.homeTypeWrapper} `}>
								<div className={classes.typeTitle}>
									<p>Building</p>
								</div>
								<div className={classes.filterButtons}>
								{data && data.unitBuildings && data.unitBuildings.map( ( building ) => (
									<FilterButton
										classes={classes}
										name={building.name}
										filterObject={building}
										data={data}
										hooks={hooks}
										listItemId={building.id}
										filterType={filterableSources.buildings.unitRelationDataType}
										filterSettings={hooks.globalUnitsFilter.globalUnitsFilter.filter}
									/>
								))}
								</div>
							</div>
							
							
							
						</div>
						<Box
							sx={{
								width: '100%',
								display: 'grid',
								gridTemplateColumns: 'repeat(2, 1fr)'
							}}
						>
							<div className={classes.moreDetailsButtonArea}>
								<ButtonOutline
									label="Clear All"
									padding={20}
									textColour={ThemeData.colours.primaryAccent}
									hoverTextColour={ThemeData.colours.white}
									borderColour={ThemeData.colours.white}
									backgroundColour={ThemeData.colours.clay05}
									onClick={() => {
										hooks.globalUnitsFilter.resetFilter()
										setClearSlider(true)
									}}
								/>
							</div>
							<div className={classes.moreDetailsButtonArea}>
								<ButtonOutline
									label="Apply"
									padding={20}
									textColour={ThemeData.colours.primaryAccent}
									hoverTextColour={ThemeData.colours.white}
									border="none"
									backgroundColour={ThemeData.colours.clay05}
									onClick={() => {
										setDoShowFilter(false)
									}}
								/>
							</div>
						</Box>
				</AnimatePresence>
			</div>
		</motion.div>
        </div>
	);
}


const FilterModal = ({doShowFilter, setDoShowFilter, modelType}) => {

	const globalUnitsFilter = useGlobalUnitsFilter();

	const { loading, error, data } = useQuery( QueryData.unitProperties );
	const [filteredUnitsData, setFilteredUnitsData] = useState([]);
	const hooks = {
		globalUnitsFilter,
	}
	useEffect(() => {
		if(data) {
			setFilteredUnitsData(globalUnitsFilter.applyFilter(data.units))
		}
	},[data, globalUnitsFilter.globalUnitsFilter]);
	
	const classes = useStyles();

	if ( loading ) return UnitFilterRender( classes, null, doShowFilter, setDoShowFilter, null, null );
	if ( error ) return UnitFilterRender( classes, null, doShowFilter, setDoShowFilter, null, error );
	
	return UnitFilterRender( classes, data, doShowFilter, setDoShowFilter, modelType, hooks );
};

export default FilterModal;