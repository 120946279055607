import React, {
	useState,
	useContext,
} from "react";

import {
	createUseStyles,
} from "react-jss";

import {
	motion,
} from "framer-motion";

import ThemeData from "data/ThemeData";
import ConstantData from "data/ConstantData";
import SiteConfigData from "data/SiteConfigData";
import UnitCardsParent from "./UnitCardsParent";
import UnitDetailCardGeneric from "./UnitDetailCardGeneric";
import UnitDetailTableItem from "./UnitDetailTableItem";
import UnitDetailPane from "./UnitDetailPane";
import VirtualTour from "./VirtualTour";
import preventClickEventPassthrough from "util/preventClickEventsPassthrough";
import useOpenClosePane from "hooks/useOpenClosePane";
import useSiteConfiguration from "hooks/useSiteConfiguration";
import UnitFcnContext from "contexts/UnitFcnContext";
import ViewStudy from "./ViewStudy";
import FilterModal from "./FilterModal";


const useStyles = createUseStyles(
	{
		wrapper: props => ( {
			height: '100%',
			width: '100%',
			boxSizing: 'border-box',
			overflow: 'hidden',
			color: ThemeData.colours.white,
			backgroundColor: ThemeData.colours.white,
			letterSpacing: '.025em',
		} ),
		contentContainer: {
			display: 'flex',
			flexDirection: 'column',
			width: '100%',
			height: '100%',
			overflow: 'hidden',
		},
		topRightContainer: {
			position: 'absolute',
			top: 60,
			right: 60,
			display: 'flex',
			alignItems: 'center',
		},
		closeX: {
			marginTop: 10,
			marginLeft: 50,
			cursor: 'pointer',
		},
		sendToEmailButton: {
			display: 'inline-block',
			width: 150,
			fontSize: 12,
			letterSpacing: '.1em',
		},
		savedHomesTitle: {
			textAlign: 'left',
			fontSize: 24,
			letterSpacing: '.15em',
		},
		top: {
			flex: '0 0 auto',
		},
		addHomesButton: {
			width: 220,
			fontSize: 12,
			letterSpacing: '.1em',
			borderRadius: 25,
		},
		middle: {
			flex: '1 0 auto',
		},
		unitCard: {
			color: ThemeData.colours.black,
		},
		listModeWrapper: {
			height: 'calc( 100vh - var( --header-height ) )',
			/* safari vh fix */
			height: 'calc( var( --app-height) - var( --header-height ))'
		},
		listModeUnit: {
			height: '100%',
			overflowY: 'scroll',
			'&::-webkit-scrollbar':{
				display: 'none'
			},
			scrollbarWidth: 'none',
		},
		tableWrapper: {
			padding: '0 0 0 10px',
			boxSizing: 'border-box',
		},
		tableItemRowsWrapper: {
			flexDirection: 'column',
			flexWrap: 'nowrap',
			justifyContent: 'start',
			paddingTop: 0,
		},
		tableItemRow: {
			flex: '0 0 auto',
		},
		bottom: {
			flex: '0 0 auto',
			display: 'flex',
			boxSizing: 'border-box',
			paddingTop: 25,
			height: 130,
			borderTop: `1px ${ThemeData.colours.white50} solid`,
		},
	},
	{
		name: "FloorplansGeneric",
	},
)

const RenderFloorplansGeneric = ( classes, data, error, isOpen, toggleIsOpen, hooks, props ) => {

	if ( error ) {
		console.log( "RenderFloorplans", error.message );
	}

	const [doShowFilter, setDoShowFilter] = useState( false );
	const siteConfiguration = useSiteConfiguration();


	const {
		selectedUnitId,
		setSelectedUnitId,
		setIsUnitDetailOpen,
		toggleIsUnitDetailOpen,
	} = useContext( UnitFcnContext );


	const [unitFilterViewMode, setUnitFilterViewMode] = useState( ConstantData.unitFilterViewModes.card );

	// const {
	// 	isVirtualTourOpen,
	// 	toggleIsVirtualTourOpen,
	// } = useOpenClosePane( "VirtualTour" )

	return (
		<>
			<FilterModal
				doShowFilter={doShowFilter}
				setDoShowFilter={setDoShowFilter} 
			/>
			<motion.div
				className={classes.wrapper}
				onMouseDown={( e ) => preventClickEventPassthrough( e )}
				onMouseUp={( e ) => preventClickEventPassthrough( e )}
			>
				{unitFilterViewMode === ConstantData.unitFilterViewModes.card ?
					<>
						<UnitCardsParent
							unitFilterViewMode={unitFilterViewMode}
							setUnitFilterViewMode={setUnitFilterViewMode}
							selectedUnitId={selectedUnitId}
							setSelectedUnitId={setSelectedUnitId}
							setSelectedUnitData={props.setSelectedUnitData}
							doShowFilter={doShowFilter}
							setDoShowFilter={setDoShowFilter}
						>
							{( className, dataUnit ) => (
								<UnitDetailCardGeneric
									className={className}
									dataUnit={dataUnit}
									toggleUnitDetailPaneFcn={toggleIsUnitDetailOpen}

									onClickLeftButton={() => setIsUnitDetailOpen( true )}
									toggleVirtualTourPaneFcn={
										( props.isToUseEmbeddedVirtualTour && props.toggleIsVirtualTourOpen ) ||
										props.toggleIsVirtualTourOpen
									}
									hooks={hooks}
									isToAllowSelection={props.isToAlwaysAllowSelection}
									toggleIsViewStudySlideUpOpenFcn={props.toggleIsViewStudySlideUpOpenFcn}
									selectedUnitId={selectedUnitId}
									setSelectedUnitId={setSelectedUnitId}
									setSelectedUnitData={props.setSelectedUnitData}
								/>
							)}
						</UnitCardsParent>
					</>
					:
					<UnitCardsParent
						fullItemsWrapperClassName={classes.tableWrapper}
						childWrapperClassName={classes.tableItemRowsWrapper}
						childClassName={classes.tableItemRow}
						unitFilterViewMode={unitFilterViewMode}
						setUnitFilterViewMode={setUnitFilterViewMode}
						unitTableItemColumnsToDisplay={SiteConfigData.unitTableItemColumns}
						doShowFilter={doShowFilter}
						setDoShowFilter={setDoShowFilter}
						doSelectFirstItem={true}
						selectedUnitId={selectedUnitId}
						setSelectedUnitId={setSelectedUnitId}
						setSelectedUnitData={props.setSelectedUnitData}

						dualPaneLeftPane={props.isToShowDualPaneForTableDisplay && (
							<div className={classes.listModeUnit}>
								{props.selectedUnitData && <UnitDetailPane
									dataUnit={props.selectedUnitData}

									setSelectedUnitId={setSelectedUnitId}
									toggleIsUnitDetailOpen={toggleIsUnitDetailOpen}
								/>}
							</div>
						)}
					>
						{( className, dataUnit, itemIdx, isFirstItem, toggleSavedHome ) => (
							<UnitDetailTableItem
								className={className}
								dataUnit={dataUnit}
								itemIdx={itemIdx}
								isFirstItem={isFirstItem}
								toggleSavedHome={toggleSavedHome}
								unitTableItemColumnsToDisplay={SiteConfigData.unitTableItemColumns}

								unitFilterViewMode={unitFilterViewMode}

								isToAllowSelection={true}
								selectedUnitId={selectedUnitId}
								setSelectedUnitId={setSelectedUnitId}
								setSelectedUnitData={props.setSelectedUnitData}

								toggleIsUnitDetailOpen={toggleIsUnitDetailOpen}
							/>
						)}
					</UnitCardsParent>
				}
			</motion.div>
			{props.isToUseEmbeddedVirtualTour &&
				<VirtualTour
					isOpen={props.isVirtualTourOpen}
					src={siteConfiguration.data && siteConfiguration.data.virtual_tour_iframe_url}
					toggleVirtualTourFcn={props.toggleIsVirtualTourOpen}
					doUseHeader={false}
				/>
			}
			<ViewStudy
				isOpen={props.isViewStudySlideUpOpen}
				toggleViewStudyFcn={props.toggleIsViewStudySlideUpOpen}
				viewUrl={props.viewUrl}
			/>
		</>
	)
}



const FloorplansGeneric = ( { isOpen, toggleIsOpen, ...props } ) => {

	const classes = useStyles( props );

	return RenderFloorplansGeneric( classes, null, null, isOpen, toggleIsOpen, null, props );

}

FloorplansGeneric.defaultProps = {
	isToAlwaysAllowSelection: false,
	isToShowDualPaneForTableDisplay: true,
	isToUseEmbeddedVirtualTour: true,
	overrideBackgroundColor: null,
};



export default FloorplansGeneric;