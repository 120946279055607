import React, {
	useState,
	useRef,
} from "react";

import * as ReactDOMServer from "react-dom/server"

import {
	Route,
	Switch,
	useLocation,
} from "react-router-dom";

import {
	AnimatePresence,
} from 'framer-motion';

import { createUseStyles } from "react-jss";

import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material";

import "mapbox-gl/dist/mapbox-gl.css";

import ThemeData from "./data/ThemeData";
import SlugData from "./data/SlugData";

import Section from "./components/Section";
import Proximation from "./components/Proximation";
import UnitDetail from "components/UnitDetail";

import useOpenClosePane from "hooks/useOpenClosePane";
import useSiteConfiguration from "hooks/useSiteConfiguration";

import UnitFcnContext from "contexts/UnitFcnContext";

import montserratBold from "./fonts/Montserrat-Bold.woff";
import montserratRegular from "./fonts/Montserrat-Regular.woff";
import montserratLight from "./fonts/Montserrat-Light.woff";
import SavedHomesSlideout from "components/SavedHomesSlideout";
import SavedHomesCompare from "components/SavedHomesCompare";
import Register from "components/Register";
import CookieConsent from "react-cookie-consent";
import ConstantData from "data/ConstantData";
import PoliciesCopyData from "data/PoliciesData";
import PoliciesModal from "components/PoliciesModal";
import { useQuery } from "@apollo/client";
import QueryData from "data/QueryData";

const useStyles = createUseStyles(
	{
		'@font-face': [
			{
				fontFamily: 'Montserrat Bold', 
				src: [`url( '${montserratBold}' ) format('woff')`],
				fontWeight: 300,
				style: 'normal'
			},
			{
				fontFamily: 'Montserrat Regular', 
				src: [`url( '${montserratRegular}' ) format('woff')`],
				fontWeight: 200,
				style: 'normal'
			},
			{
				fontFamily: 'Montserrat Light',
				src: [`url( '${montserratLight}' ) format( 'woff' )`],
				fontWeight: 'normal',
				style: 'normal',
			},
		],
		root: {
			height: '100vh',
			height: '100%',
			color: ThemeData.colours.primaryFont,
			backgroundColor: ThemeData.colours.primaryBg,
			'--header-height': '100px',
			'--app-height': '100%'
		},
		cookieContainer: {
			border: '1px solid rgba(238, 237, 230, 0.5)',
			margin: '0 0 2% 2%',
			textAlign: 'left',
			padding: "0px 40px 0px 40px"
		},
		cookieContent: {
			fontSize: 16,
			height: 100,
			fontWeight: 100
		},
		cookieButton: {
			background: 'rgb(255,255,255) !important',
			color: 'black',
			height: 60,
			width: 195
		},
		cookieDeclineButton: {
			display: 'none'
		},
		cookieButtonWrapper: {
			position: 'absolute',
			top: 160,
		},
		continueAnywaysButton: {
			marginTop: 60,
			cursor: 'pointer',
			fontSize: 12,
			color: ThemeData.colours.primaryAccent,
			fontFamily: ThemeData.fonts.title
		},
		optimalViewingDialog: {
			margin: 30,
			textAlign: 'center',
			textTransform: 'uppercase',
			letterSpacing: '0.1em',
			lineHeight: '30px'
		},
		optimalViewingTitle: {
			fontSize: 32,
			color: ThemeData.colours.white,
			fontFamily: ThemeData.fonts.title
		},
		optimalViewingText: {
			margin: 30,
			fontSize: 18,
			color: ThemeData.colours.white,
			fontFamily: ThemeData.fonts.copy
		},
		optimalViewingDialogContainer: {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			height: '100vh',
			width: '100vw',
			backgroundColor: ThemeData.colours.primaryBg,
		}
	},
	{ name: 'Root' },
)

function Root() {

	const location = useLocation();

	const classes = useStyles();

	const theme = useTheme();
	const isOptimalViewing = useMediaQuery(theme.breakpoints.up('sm'));
	const [continueWithoutOptimalViewing, setContinueWithoutOptimalViewing] = useState(false);

	const proxima = useRef( null );
	const proximaContainer = useRef( null );
	const siteConfiguration = useSiteConfiguration();
	const [isProximaLoaded, setIsProximaLoaded] = useState( false );

	const [selectedUnitId, setSelectedUnitId] = useState( -1 );

	const [isSavedHomesSlideoutOpen, setIsSavedHomesSlideoutOpen] = useState( false );
	const toggleIsSavedHomesSlideoutOpen = () => setIsSavedHomesSlideoutOpen( !isSavedHomesSlideoutOpen );
	const [isSavedHomesCompareOpen, setIsSavedHomesCompareOpen] = useState( false );
	const toggleIsSavedHomesCompareOpen = () => setIsSavedHomesCompareOpen( !isSavedHomesCompareOpen );
	const [isSavedHomesCompareListOpen, setIsSavedHomesCompareListOpen] = useState( false );

	const [isRegisterOpen, setIsRegisterOpen] = useState( false );
	const toggleIsRegisterOpen = () => setIsRegisterOpen( !isRegisterOpen );
	const [isSendToEmailOpen, setIsSendToEmailOpen] = useState( false );
	const toggleIsSendToEmailOpen = () => setIsSendToEmailOpen( !isSendToEmailOpen );

	const {
		isUnitDetailOpen,
		setIsUnitDetailOpen,
		toggleIsUnitDetailOpen,
	} = useOpenClosePane( 'UnitDetail' );

	const {
		isPrivacyPolicyDialogOpen,
		setIsPrivacyPolicyDialogOpenToOpen,
		setIsPrivacyPolicyDialogOpenToClosed,
	} = useOpenClosePane( "PrivacyPolicyDialog" );

	const {
		isCookiePolicyDialogOpen,
		setIsCookiePolicyDialogOpenToOpen,
		setIsCookiePolicyDialogOpenToClosed,
	} = useOpenClosePane( "CookiePolicyDialog" );

	const unitFcns = {
		selectedUnitId,
		setSelectedUnitId,
		isUnitDetailOpen,
		setIsUnitDetailOpen,
		toggleIsUnitDetailOpen,
	};



	const handlePrivacyPolicyClick = (e, openDialog) => {
		const el = e.target.closest('b');
		if(el && e.currentTarget.contains(el)) {
			setIsCookiePolicyDialogOpenToOpen();
		}
	}
	const { data } = useQuery(QueryData.global);
	return (
		<UnitFcnContext.Provider value={unitFcns}>
			{!isOptimalViewing && !continueWithoutOptimalViewing
			?
			<div className={classes.optimalViewingDialogContainer}>
				<div className={classes.optimalViewingDialog}>
					<div className={classes.optimalViewingTitle}>OOPS!</div>
					<div className={classes.optimalViewingText}>For an optimal viewing experience, check back on your desktop or tablet device</div>
					<div onClick={() => setContinueWithoutOptimalViewing(true)} className={classes.continueAnywaysButton}>Click Here to Continue</div>
				</div>
			</div>
			:
			<>
				<CookieConsent
					location="bottom"
					buttonText="I UNDERSTAND"
					cookieName="ProximaByLngCookies"
					flipButtons
					expires={7}
					hideOnAccept
					enableDeclineButton
					containerClasses={classes.cookieContainer}
					buttonWrapperClasses={classes.cookieButtonWrapper}
					contentClasses={classes.cookieContent}
					buttonClasses={classes.cookieButton}
					declineButtonClasses={classes.cookieDeclineButton}
					style={{
						width: 645,
						height: 350,
						background: 'rgba(0, 0, 0, 1)',
						border: '1px solid rgba(238, 237, 230, 0.5)',
						margin: '0 0 2% 2%',
					}}
					buttonStyle={{
						background: 'blue'
					}}
				>
					<p style={{ fontSize: 20, fontWeight: 400 }}>Proxima uses cookies</p>
					{ConstantData.cookieCopy.cookie}
					<p 
						onClick={(e) => handlePrivacyPolicyClick(e)}
						style={{ 
								fontSize: 14, 
								fontWeight: 100, 
								position: 'absolute', 
								bottom: 30 
							}} 
							dangerouslySetInnerHTML={{ __html: ReactDOMServer.renderToString(ConstantData.cookieCopy.privacyPolicy) }} 
						/>
				</CookieConsent>
				<div className={`${classes.root}`}>
					<AnimatePresence exitBeforeEnter>
						<Switch location={location} key={location.pathname}>
							<Route path="/proximation">
								<Proximation
									proxima={proxima}
									proximaContainer={proximaContainer}
									isProximaLoaded={isProximaLoaded}
									setIsProximaLoaded={setIsProximaLoaded}
									isUnitDetailOpen={isUnitDetailOpen}
									setUnitDetailOpen={setIsUnitDetailOpen}
									toggleIsSavedHomesSlideoutOpen={toggleIsSavedHomesSlideoutOpen}
									isSavedHomesSlideoutOpen={isSavedHomesSlideoutOpen}
									toggleIsSavedHomesCompareOpen={toggleIsSavedHomesCompareOpen}
									isSavedHomesCompareOpen={isSavedHomesCompareOpen}
									toggleIsRegisterOpen={toggleIsRegisterOpen}
									toggleIsSendToEmailOpen={toggleIsSendToEmailOpen}
									// {...unitFcns}
								/>
							</Route>
							<Route path="/:slug">
								<Section
									toggleIsSavedHomesCompareOpen={toggleIsSavedHomesCompareOpen}
									toggleIsSavedHomesSlideoutOpen={toggleIsSavedHomesSlideoutOpen}
									isSavedHomesCompareOpen={isSavedHomesCompareOpen}
									isSavedHomesSlideoutOpen={isSavedHomesSlideoutOpen}
									toggleIsRegisterOpen={toggleIsRegisterOpen}
									toggleIsSendToEmailOpen={toggleIsSendToEmailOpen}
									{...unitFcns}
								/>
							</Route>
							<Route path="/">
								<Section 
									toggleIsSavedHomesCompareOpen={toggleIsSavedHomesCompareOpen}
									toggleIsSavedHomesSlideoutOpen={toggleIsSavedHomesSlideoutOpen}
									isSavedHomesCompareOpen={isSavedHomesCompareOpen}
									isSavedHomesSlideoutOpen={isSavedHomesSlideoutOpen}
									toggleIsRegisterOpen={toggleIsRegisterOpen}
									toggleIsSendToEmailOpen={toggleIsSendToEmailOpen}
									sectionSlug={SlugData.HOME_SLUG_NAME} />
							</Route>
							<Route path="/404.html">
								<div>
									<h2>404 Error</h2>
									<p>The page you are trying to reach is not here.</p>
								</div>
							</Route>
						</Switch>
					</AnimatePresence>
					<UnitDetail
						id="unit-detail"
						unitId={selectedUnitId}
						isOpen={isUnitDetailOpen}
						toggleUnitDetailFcn={() => toggleIsUnitDetailOpen()}
						setSelectedUnitId={setSelectedUnitId}
						isSavedHomesCompareListOpen={isSavedHomesCompareListOpen}
					/>
				</div>
				<SavedHomesSlideout
						isOpen={isSavedHomesSlideoutOpen}
						toggleIsOpen={() => toggleIsSavedHomesSlideoutOpen()}
						toggleIsSavedHomesCompareOpen={() => toggleIsSavedHomesCompareOpen()}
						toggleIsRegisterOpen={toggleIsSendToEmailOpen}
				/>
				<SavedHomesCompare
						isUnitDetailOpen={isUnitDetailOpen}
						isOpen={isSavedHomesCompareOpen}
						toggleIsOpen={() => toggleIsSavedHomesCompareOpen()}
						toggleIsSavedHomesSlideoutOpen={() => toggleIsSavedHomesSlideoutOpen()}
						toggleIsRegisterOpen={toggleIsSendToEmailOpen}
						toggleIsUnitDetailOpen={toggleIsUnitDetailOpen}
						isSavedHomesCompareListOpen={isSavedHomesCompareListOpen}
						setIsSavedHomesCompareListOpen={setIsSavedHomesCompareListOpen}
				/>
				<Register
				doShow={isRegisterOpen}
				toggleIsOpen={() => toggleIsRegisterOpen()}
				titleText={siteConfiguration.dataStateIsLoaded && siteConfiguration.data.email_register_title_text}
				buttonText={siteConfiguration.dataStateIsLoaded && siteConfiguration.data.email_register_button_text}
				/>
				<Register
					doShow={isSendToEmailOpen}
					toggleIsOpen={() => toggleIsSendToEmailOpen()}
					titleText={siteConfiguration.dataStateIsLoaded && siteConfiguration.data.email_floorplans_title_text}
					buttonText={siteConfiguration.dataStateIsLoaded && siteConfiguration.data.email_floorplans_button_text}
				/>
				<PoliciesModal
					data={data && data.global.cookiePolicy}
					isDialogOpen={isCookiePolicyDialogOpen}
					setIsDialogOpenToClosed={() => setIsCookiePolicyDialogOpenToClosed()}
					onClickFcn={() => setIsPrivacyPolicyDialogOpenToOpen()}
				/>
				<PoliciesModal
					data={PoliciesCopyData.privacyPolicy}
					isDialogOpen={isPrivacyPolicyDialogOpen}
					setIsDialogOpenToClosed={setIsPrivacyPolicyDialogOpenToClosed}
				/>
			</>
			}
		</UnitFcnContext.Provider>
	);
}

export default Root;
