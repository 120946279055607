import React from "react";
import {
	createUseStyles
} from "react-jss";

import useSavedHomes from "hooks/useSavedHomes";

import imgFunctions from "util/imgFunctions";

import ButtonGroup from "./ButtonGroup";
import ButtonOutline from "./ButtonOutline";
import UnitInfoPlanNameTitle from "components/UnitInfoPlanNameTitle";
import UnitInfoLine from "./UnitInfoLine";
import UnitInfoIconList from "./UnitInfoIconList";

import ThemeData from "../data/ThemeData";
import ConstantData from "data/ConstantData";
import { BookMarkButton } from "./BookMarkButton";
import { useQuery } from "@apollo/client";
import QueryData from "data/QueryData";
const useStyles = createUseStyles(
	{
		wrapper: {
			position: 'relative',
			width: '100%',
			height: '100%',
			boxSizing: 'border-box',
			display: 'flex',
			flexDirection: 'column',
			padding: 30,
			backgroundColor: ThemeData.colours.cardBg,
			'& > div': {
				flex: '0 0 auto',
			},
		},
		hidden: {
			display: 'none',
		},
		floorplanImage: {
			minHeight: 200,
			flex: '1 1 auto !important',

			borderBottom: `1px ${ThemeData.colours.mainBorder} solid`,

			'& > img': {
				width: '100%',
				height: '100%',
				objectFit: 'contain',
			},
		},
		'$floorplanImage, $details': {
			borderBottom: `1px ${ThemeData.colours.mainBorder} solid`,
		},
		details: {
			position: 'relative',
			minHeight: 63,
			textAlign: 'left',

			'& p': {
			},
		},
		hr: {
			width: '100%',
			borderTop: `1px ${ThemeData.colours.lightBorderDarkOnLight} solid`,
		},
		unitInfoPlanNameTitle: {
			margin: 0,
			padding: 0,
			color: ThemeData.colours.secondaryFont,
			fontFamily: ThemeData.fonts.title,
			fontSize: '36px',
		},
		unitInfoLine: {
			marginTop: 10,
			color: ThemeData.colours.grey,
			fontFamily: ThemeData.fonts.titleSub,
			fontSize: '16px',
			letterSpacing: '0.05em',
		},
		unitInfoIconList: {
			color: ThemeData.colours.secondaryFont,
		},
		unitInfoList: {

		},
		actionButton: {
			position: 'absolute',
			top: 16,
			right: 20,
			fontSize: 20,
		},
		buttonArea: {
			padding: 25,
			paddingBottom: 0,
			margin: '0 auto',
			width: 200

		},
		moreDetailsButtonArea: {
			borderTop: `1px ${ThemeData.colours.eggshellTextBg} solid`,
		},
		disclaimerWrapper: {
			marginTop: 25
		},
		disclaimer: {
			fontStyle: 'italic',
			fontWeight: 100,
			textAlign: 'center',
			color: 'rgba(0, 0, 0, 0.75)',
			margin: 0
		}
	},
	{
		name: "UnitDetailPane",
	}
)

function renderUnitDetailPane( selectedUnitId, dataUnit, classes, error, toggleUnitDetailPaneFcn, hooks, props ) {
	const { disclaimerData } = ConstantData;
	const doDisplayCard = true;
	return (
		<div className={`${classes.wrapper} ${doDisplayCard ? "" : classes.hidden}`}>
			<div className={classes.details}>
				{dataUnit && <UnitInfoPlanNameTitle
					data={dataUnit}
					fontSize={36}
					doShowUnitNumber={true}
					className={classes.unitInfoPlanNameTitle}
				/>}
				<div className={classes.unitInfoLine}>
					{dataUnit && <UnitInfoLine
						data={dataUnit}
					/>}
				</div>
			</div>
			<div className={classes.floorplanImage}>
				{dataUnit && dataUnit.unit_plan_type && dataUnit.unit_plan_type.floorplan_image &&
					<img
						src={`${imgFunctions.GetImgBySizeUri( dataUnit.unit_plan_type.floorplan_image, ConstantData.imgSizes.large )} `}
						alt={dataUnit.unit_plan_type.floorplan_image.alternativeText}
					/>
				}
			</div>
			<div className={classes.hr} />
			<UnitInfoIconList
				className={classes.unitInfoIconList}
				dataUnit={dataUnit}
				marginTopItemsRow={25}
			/>
			<div className={classes.hr} />
			<div className={classes.buttonArea}>
				<ButtonGroup>
					<ButtonOutline
						label="Details"
						textColour={ThemeData.colours.primaryFontHighestConstrast}
						backgroundColour={ThemeData.colours.primaryAccent}
						borderColour={ThemeData.colours.grey10}
						isRounded={true}

						to="."
						onClick={() => {
							props.setSelectedUnitId( dataUnit.custom_id );
							props.toggleIsUnitDetailOpen();
						}}
						firstInColl
					/>
					
					<BookMarkButton hooks={hooks} dataUnit={dataUnit} height={40} width={30} />
				</ButtonGroup>
			</div>
			<div className={classes.disclaimerWrapper}>
				{/* {TODO: replace with strapi location: currently using hardcoded json file} */}
				{/* <p>{hooks.disclaimerData.data.contactInfo.disclaimer_2}</p> */}
				<p className={classes.disclaimer}>{disclaimerData.disclaimer_2}</p>
				<p className={classes.disclaimer}>{disclaimerData.disclaimer_3}</p>
			</div>
		</div>
	);
}


function UnitDetailPane( { dataUnit, selectedUnitId, toggleUnitDetailPaneFcn, ...props } ) {
	const classes = useStyles();
	const { savedHomes, toggleSavedHome, savedHomesIncludes } = useSavedHomes();
	const disclaimerData = useQuery( QueryData.navQuery )
	let hooks = {
		savedHomes,
		toggleSavedHome,
		savedHomesIncludes,
		disclaimerData
	}

	return renderUnitDetailPane( selectedUnitId, dataUnit, classes, null, toggleUnitDetailPaneFcn, hooks, props );
}



export default UnitDetailPane;