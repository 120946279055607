import React, {

} from "react";

import {
	createUseStyles,
} from "react-jss";

import ButtonOutline from "./ButtonOutline";

import ThemeData from "data/ThemeData";
import useSavedHomes from "hooks/useSavedHomes";
import { BookMarkButton } from "./BookMarkButton";

const useStyles = createUseStyles(
	{
		wrapper: {
			height: 80,
			width: '100%',
			borderRadius: 4,
			display: 'flex',
			boxSizing: 'border-box',
			alignItems: 'center',
			flexDirection: 'row',
			color: ThemeData.colours.secondaryFont,
			margin: '8px 0',
			textAlign: 'center',
			border: '1px solid #EEEDE6',
			justifyContent: 'space-between',
			// paddingRight: 20
		},
		selectedItem: {
			backgroundColor: ThemeData.colours.primaryAccentFade,
			border: `1px solid ${ThemeData.colours.primaryAccent}`
		},
		floorplanImg: {
			flex: '0 0 auto',
			width: 82,
			margin: '0 2%',
			backgroundColor: ThemeData.colours.clear,
		},
		info: {
			flex: '1 0 auto',
		},
		unitInfoPlanNameTitle: {
			marginTop: 0,
		},
		unitInfoLine: {
			color: ThemeData.colours.white75,

			textTransform: 'uppercase',
		},
		removeHomeButton: {
			flex: '0 0 auto',
			width: 48,
		},
		tableItem: {
			flex: '1 0 auto',
			width: 100,
			flexDirection: 'row'
		},
		moreDetailsButtonArea: {
			zIndex: 2,
			marginRight: 15
		},
		infoTextWrapper: {
			display: 'flex',
			flexDirection: 'column',
			minWidth: 255
		},
		planAvailableWrapper: {
			display: 'flex',
			flexDirection: 'row'
		},
		planInfoWrapper: {
			display: 'flex',
			flexDirection: 'row',
			fontSize: 14,
			fontWeight: 'lighter',
			paddingTop: 5,
			'& > div' :{
				padding: '0 3px',
				fontWeight: 'lighter',
			},
			'@media (max-width: 1200px)':{
				fontSize: 11
			}
		},
		planText: {
			fontSize: 16,
		},
		availabilityText: {
			backgroundColor: props => props.available ? ThemeData.colours.neuVibrantGreen : '#EEEDE6',
			borderRadius: 4,
			padding: '3px 4px 2px 4px',
			marginLeft: 8
		},
		moreDetails: {
			borderRadius: 4,
			'@media (max-width: 1200px)':{
				fontSize: 9
			}
		}
	},
	{
		name: "UnitDetailTableItem",
	}
)



const UnitDetailTableItem = ( { dataUnit, ...props } ) => {
	const available = dataUnit.unit_availability_state.value.toUpperCase() === 'AVAILABLE';
	const classes = useStyles({ available });
	const { savedHomes, toggleSavedHome, savedHomesIncludes } = useSavedHomes();
	let hooks = {
		savedHomes,
		toggleSavedHome,
		savedHomesIncludes,
	};
	return (
		<>
			<div
				id={dataUnit.custom_id === props.selectedUnitId ? `unit-detail-tableItem-${props.itemIdx}` : `unit-detail-tableItem-active-${props.itemIdx}`}
				className={
					`${classes.wrapper
					} ${props.itemIdx % 2 === 0 ?
						classes.rowBGColourOne :
						classes.rowBGColourTwo
					} ${dataUnit.custom_id === props.selectedUnitId ? classes.selectedItem : ''
					}`}
				onClick={() => {
					props.isToAllowSelection && props.setSelectedUnitId( dataUnit.custom_id );
				}}
			>
				<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: 10 }}>
					<img className={classes.floorplanImg} src={dataUnit.floorplan_image.url} alt="floor_plan_img"/>
					<div className={classes.infoTextWrapper}>
						<div className={classes.planAvailableWrapper}>
							<div className={classes.planText}>Plan {dataUnit.unit_plan_type.name} | Unit {dataUnit.unit_number}</div>
							<div className={classes.availabilityText}>{dataUnit.unit_availability_state.value.toUpperCase()}</div>
						</div>
						<div className={classes.planInfoWrapper}>
							<div>{dataUnit.unit_home_type.name}</div>
								&middot;
							<div>{dataUnit.baths}</div>
								&middot;
							<div>{dataUnit.interior_sqft} sqft</div>
						</div>
					</div>
				</div>
				<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 10 }}>
					<div className={classes.moreDetailsButtonArea}>
						<ButtonOutline
							id={`more-details-button-list-view-${props.itemIdx}`}
							className={classes.moreDetails}
							label="Details"
							padding={10}
							textColour={ThemeData.colours.primaryAccent}
							hoverTextColour={ThemeData.colours.white}
							border="1px solid #EEEDE6"
							backgroundColour={ThemeData.colours.white}
							onClick={props.toggleIsUnitDetailOpen}
							borderRadius={4}
						/>
					</div>
					<BookMarkButton dataUnit={dataUnit} hooks={hooks} height={30} width={20} margin={'0px 10px 0px 0px'}/>
				</div>
			</div>
		</>
	);
}



UnitDetailTableItem.defaultProps = {
	isToAllowSelection: false,
}



export default UnitDetailTableItem;