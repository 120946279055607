import React, {
	useEffect,
	useState,
	useCallback,
	useContext,
} from "react";

import { createUseStyles } from "react-jss";

import {
	motion,
} from "framer-motion";

import Carousel, { Modal, ModalGateway } from "react-images";

import ThemeData from "../data/ThemeData";
import ConstantData from "data/ConstantData";

import useOpenClosePane from "hooks/useOpenClosePane";
import useGlobalUnitsFilterResult from "hooks/useGlobalUnitsFilterResult";
import useSiteConfiguration from "hooks/useSiteConfiguration";

import UnitFcnContext from "contexts/UnitFcnContext";

import Layout from "./Layout";
import UnitDetailCard from "./UnitDetailCard";
import ViewStudy from "./ViewStudy";
import VirtualTour from "./VirtualTour";
import UnitCardSlideout from "components/UnitCardSlideout";
import { useQuery } from "@apollo/client";
import QueryData from "data/QueryData";
import FilterHeader from "./FilterHeader";
import FilterModal from "./FilterModal";
import ButtonOutline from "./ButtonOutline";
import { FaCaretDown } from "react-icons/fa";
import { ReactComponent as FullModelIcon } from "../img/3d_model_icon.svg";
import { ReactComponent as FullModelIconActive } from "../img/3d_model_icon_active.svg";
import { ReactComponent as FloorplateModelIcon} from '../img/2d_model_icon.svg';
import { ReactComponent as FloorplateModelIconActive} from '../img/2d_model_icon_active.svg';
import { ReactComponent as ChevronRighMedium } from "../img/chevron_left_white_medium.svg";

import NetData from "../data/NetData";

const useStyles = createUseStyles( {
	canvasElementsWrapper: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		paddingRight: props => props.isSavedHomesSlideoutOpen ? 450 : 0,
		background: ThemeData.colours.white,
		paddingTop: 10,
		alignItems: 'center'
	},
	canvasWrapper: {
		height: 'calc(100vh) !important',
		/* safari vh fix */
		height: 'calc( var( --app-height) - 100px)',
		position: 'relative',
		width: '100%',
		display: props => props.doShowFilter || props.unitDetailOpen || props.isSavedHomesCompareOpen ? 'none' : '',
	},
	emptyWrapper: {
		height: 'calc( 100vh - 200px)',
		/* safari vh fix */
		height: 'calc( var( --app-height) - 200px)',
		position: 'relative',
		// width: '100%',
		backgroundColor: ThemeData.colours.white,
		display: props => props.doShowFilter || props.unitDetailOpen || props.isSavedHomesCompareOpen ? '' : 'none',
		
	},
	keyplan: {
		width: 330,
		position: 'absolute',
		right: 100,
		'@media (max-width: 1300px)': {
			right: 40,
		},
		top: 680,
		'@media (max-height: 850px)': {
			top: 630
		},
		display: 'flex',
		justifyContent: 'center',
	},
	keyplanImg: {
		border: `1px solid ${ThemeData.colours.blackBg15}`,
		backgroundColor: ThemeData.colours.white,
		padding: '10px 20px 5px 20px',
		flex: '0 0 auto',
		maxHeight: 200,
		'@media (max-height: 900px)': {
			maxHeight: 150
		},
		'@media (max-height: 800px)': {
			maxHeight: 100
		},
		objectFit: 'contain',
		marginBottom: 5
	},
	slideAndCanvasContainer: {
		display: props => props.unitDetailOpen ? 'none': 'flex',
		flexDirection: 'row'
	},
	modelViewSwitch: {
		width: 130,
		margin: '0 auto'
	},
	modelViewSwitchButtons: {
		display: 'flex',
		flexDirection: 'row',
		position: 'relative',
		height: 112,
		width: 240,
		justifyContent: 'space-evenly',
		alignItems: 'center'
	},
	iconModelSelect2D: {
		height: 50,
		width: 50,
		margin: '0 auto',
		cursor: 'pointer',
	},
	iconModelSelect3D: {
		height: 50,
		width: 50,
		margin: '0 auto',
		cursor: 'pointer',
	},
	disclaimer: {
		position: 'absolute',
		bottom: 10,
		marginLeft: 15,
		marginRight: 15,
		fontStyle: 'italic',
		fontWeight: 100,
		textAlign: 'center',
		width: 300,
		color: 'rgba(0, 0, 0, 0.75)',
		'@media (max-width: 1200px)': {
			left: props => props.isUnitSlideooutOpen && 250
		}
	},
	disclaimerWrapper: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%'
	},
	top: {
		flex: '0 0 auto',
		transition: 'left 0.5s',
	},
	closeX: {
		// position: 'absolute',
		// left: props => props.slideOutWidth,
		display: 'flex',
		alignItems: 'center',
		height: 50,
		width: props => props.isUnitCardSlideoutOpen ? 35 : 165,
		backgroundColor: ThemeData.colours.white,
		boxShadow: '0px 4px 10px rgb(0 0 0 / 33%)',
		cursor: 'pointer',
		margin: props => props.isUnitCardSlideoutOpen ? '0 25px' : '0 50px',
		zIndex: 1,
		borderRadius: 5,
	},
	openIcon: {
		flex: '0 0 auto',
		display: 'flex',
	},
	openText: {
		flex: '0 0 auto',
		paddingRight: 20,
		color: ThemeData.colours.secondaryAccent,
		fontSize: 12,
		textTransform: 'uppercase',
		letterSpacing: 4,
	},
} );

function Proximation( {
	proxima,
	proximaContainer,
	...props } ) {
	

	const {
		selectedUnitId,
		setSelectedUnitId,
		setIsUnitDetailOpen,
	} = useContext( UnitFcnContext );
	

	const siteConfiguration = useSiteConfiguration();

	useEffect( () => {
		if ( !proxima.current || !proxima.current.canvas ) {

			proxima.current = {
				pc: window.pc,
				INPUT_SETTINGS: window.INPUT_SETTINGS,
				CONFIG_FILENAME: window.CONFIG_FILENAME,
				SCENE_PATH: window.SCENE_PATH,
				PRELOAD_MODULES: window.PRELOAD_MODULES,
				loadModules: window.loadModules,
				CONTEXT_OPTIONS: window.CONTEXT_OPTIONS,
				ASSET_PREFIX: window.ASSET_PREFIX,
				SCRIPT_PREFIX: window.SCRIPT_PREFIX,
				SCRIPTS: window.SCRIPTS,
				canvas: null,
				devices: null,
				app: null,
			};

			let pc = proxima.current.pc;
			let INPUT_SETTINGS = proxima.current.INPUT_SETTINGS;
			let CONFIG_FILENAME = proxima.current.CONFIG_FILENAME;
			let SCENE_PATH = proxima.current.SCENE_PATH;
			let PRELOAD_MODULES = proxima.current.PRELOAD_MODULES;
			let loadModules = proxima.current.loadModules;
			let CONTEXT_OPTIONS = proxima.current.CONTEXT_OPTIONS;
			let ASSET_PREFIX = proxima.current.ASSET_PREFIX;
			let SCRIPT_PREFIX = proxima.current.SCRIPT_PREFIX;
			let SCRIPTS = proxima.current.SCRIPTS;

			( function () {

				var CANVAS_ID = 'application-canvas';

				// var canvas, devices, app;


				var createCanvas = function () {

					// if ( !canvas ) {
					proxima.current.canvas = document.createElement( 'canvas' );
					// 	doAppendCanvas = true;
					// }

					proxima.current.canvas.setAttribute( 'id', CANVAS_ID );
					proxima.current.canvas.setAttribute( 'tabindex', 0 );
					// canvas.style.visibility = 'hidden';

					// Disable I-bar cursor on click+drag
					proxima.current.canvas.onselectstart = function () { return false; };

					// if ( doAppendCanvas )
					proximaContainer.current.appendChild( proxima.current.canvas );
					// document.getElementById( "canvasWrapper" ).appendChild( proxima.current.canvas );
					// document.body.appendChild(canvas);

					return proxima.current.canvas;
				};

				var createInputDevices = function ( canvas ) {
					var devices = {
						elementInput: new pc.ElementInput( canvas, {
							useMouse: INPUT_SETTINGS.useMouse,
							useTouch: INPUT_SETTINGS.useTouch
						} ),
						keyboard: INPUT_SETTINGS.useKeyboard ? new pc.Keyboard( window ) : null,
						mouse: INPUT_SETTINGS.useMouse ? new pc.Mouse( canvas ) : null,
						gamepads: INPUT_SETTINGS.useGamepads ? new pc.GamePads() : null,
						touch: INPUT_SETTINGS.useTouch && pc.platform.touch ? new pc.TouchDevice( canvas ) : null
					};

					return devices;
				};

				var configureCss = function ( fillMode, width, height ) {
					// Configure resolution and resize event
					if ( proxima.current.canvas.classList ) {
						proxima.current.canvas.classList.add( 'fill-mode-' + fillMode );
					}

					// css media query for aspect ratio changes
					var css = "@media screen and (min-aspect-ratio: " + width + "/" + height + ") {";
					css += "    #application-canvas.fill-mode-KEEP_ASPECT {";
					css += "        width: auto;";
					css += "        height: 100%;";
					css += "        margin: 0 auto;";
					css += "    }";
					css += "}";

					// append css to style
					if ( document.head.querySelector ) {
						document.head.querySelector( 'style' ).innerHTML += css;
					}
				};

				var reflow = function () {
					var canvasWrapper = document.getElementById( "canvasWrapper" );
					if ( canvasWrapper ) {
						proxima.current.app.resizeCanvas( canvasWrapper.clientWidth, canvasWrapper.clientHeight );
						proxima.current.canvas.style.width = '100%';
						proxima.current.canvas.style.height = '100%';

						var fillMode = proxima.current.app._fillMode;

						if ( fillMode === pc.FILLMODE_NONE || fillMode === pc.FILLMODE_KEEP_ASPECT ) {
							proxima.current.canvas.style.marginTop = '';

						}
					}
				};

				var displayError = function ( html ) {
					var div = document.createElement( 'div' );

					div.innerHTML = [
						'<table style="background-color: #8CE; width: 100%; height: 100%;">',
						'  <tr>',
						'      <td align="center">',
						'          <div style="display: table-cell; vertical-align: middle;">',
						'              <div style="">' + html + '</div>',
						'          </div>',
						'      </td>',
						'  </tr>',
						'</table>'
					].join( '\n' );

					document.body.appendChild( div );
				};

				proxima.current.canvas = createCanvas();
				proxima.current.devices = createInputDevices( proxima.current.canvas );

				try {
					proxima.current.app = new pc.Application( proxima.current.canvas, {
						elementInput: proxima.current.devices.elementInput,
						keyboard: proxima.current.devices.keyboard,
						mouse: proxima.current.devices.mouse,
						gamepads: proxima.current.devices.gamepads,
						touch: proxima.current.devices.touch,
						graphicsDeviceOptions: proxima.current.CONTEXT_OPTIONS,
						assetPrefix: proxima.current.ASSET_PREFIX || "",
						scriptPrefix: proxima.current.SCRIPT_PREFIX || "",
						scriptsOrder: proxima.current.SCRIPTS || []
					} );
				} catch ( e ) {
					if ( e instanceof pc.UnsupportedBrowserError ) {
						displayError( 'This page requires a browser that supports WebGL.<br/>' +
							'<a href="http://get.webgl.org">Click here to find out more.</a>' );
					} else if ( e instanceof pc.ContextCreationError ) {
						displayError( "It doesn't appear your computer can support WebGL.<br/>" +
							'<a href="http://get.webgl.org/troubleshooting/">Click here for more information.</a>' );
					} else {
						displayError( 'Could not initialize application. Error: ' + e );
					}

					return;
				}

				var configure = function () {
					proxima.current.app.configure( CONFIG_FILENAME, function ( err ) {
						const app = proxima.current.app;

						if ( err ) {
							console.error( err );
						}

						configureCss( app._fillMode, app._width, app._height );

						// do the first reflow after a timeout because of
						// iOS showing a squished iframe sometimes
						setTimeout( function () {
							reflow();
							
							window.addEventListener( 'resize', reflow, false );
							window.addEventListener( 'orientationchange', reflow, false );

							app.preload( function ( err ) {
								if ( err ) {
									console.error( err );
								}

								app.loadScene( SCENE_PATH, function ( err, scene ) {
									if ( err ) {
										console.error( err );
									}

									app.start();


									props.setIsProximaLoaded( true );
								} );
							} );
						} );
					} );
				};

				if ( PRELOAD_MODULES.length > 0 ) {
					loadModules( PRELOAD_MODULES, ASSET_PREFIX, configure );
				} else {
					configure();
				}


			} )();

			pc.script.createLoadingScreen( function ( app ) {
				var showSplash = function () {
					// splash wrapper
					var wrapper = document.createElement( 'div' );
					wrapper.id = 'application-splash-wrapper';
					document.body.appendChild( wrapper );

					// splash
					var splash = document.createElement( 'div' );
					splash.id = 'application-splash';
					wrapper.appendChild( splash );
					splash.style.display = 'none';

					var logo = document.createElement( 'img' );
					logo.src = ASSET_PREFIX + 'logo.png';
					splash.appendChild( logo );
					logo.onload = function () {
						splash.style.display = 'block';
					};

					var container = document.createElement( 'div' );
					container.id = 'progress-bar-container';
					splash.appendChild( container );

					var bar = document.createElement( 'div' );
					bar.id = 'progress-bar';
					container.appendChild( bar );

				};

				var hideSplash = function () {
					var splash = document.getElementById( 'application-splash-wrapper' );
					splash.parentElement.removeChild( splash );
				};

				var setProgress = function ( value ) {
					var bar = document.getElementById( 'progress-bar' );
					if ( bar ) {
						value = Math.min( 1, Math.max( 0, value ) );
						bar.style.width = value * 100 + '%';
					}
			};

				var createCss = function (modalOpen) {
					var css = [
						'body {',
						`    background-color: ${ThemeData.colours.primaryBg};`,
						'}',

						'#application-canvas {',
						'	position: relative',
						'}',
						
						'#application-canvas:after {',
							'content: "";',
							'position:absolute;',
							'z-index:999;',
							'top:0;',
							'left:0;',
							'width:100%;',
							'height:100%;',
							'cursor: not-allowed;',    
							'pointer-events: none;',
						'}',

						'#application-splash-wrapper {',
						'    position: absolute;',
						'    top: 100px;',
						'    left: 0;',
						'    height: calc( 100vh - 100px) !important;',
						'    height: calc( var(--app-height) - 100px);',
						'    width: 100%;',
						`    background-color: ${ThemeData.colours.primaryBg};`,
						'}',

						'#application-splash {',
						'    position: absolute;',
						'    top: 46%;',
						'    width: 512px;',
						'    left: 50%;',
						'		 transform: translate(-50%, -50%);',
						'		 -moz-transform: translate(-50%, -50%);',
						'		 -webkit-transform: translate(-50%, -50%);',
						'}',

						'#application-splash img {',
						'    width: 100%;',
						'    margin-bottom: 25px;',
						'}',

						'#progress-bar-container {',
						'    margin: 20px auto 0 auto;',
						'    height: 2px;',
						'    width: 100%;',
						`    background-color: ${ThemeData.colours.primaryAccentFade}`,
						'}',

						'#progress-bar {',
						'    width: 0%;',
						'    height: 100%;',
						`    background-color: ${ThemeData.colours.primaryAccent};`,
						'}',
						'@media (max-width: 480px) {',
						'    #application-splash {',
						'        width: 170px;',
						'        left: calc(50% - 85px);',
						'    }',
						'}'

					].join( '\n' );

					var style = document.createElement( 'style' );
					style.type = 'text/css';
					if ( style.styleSheet ) {
						style.styleSheet.cssText = css;
					} else {
						style.appendChild( document.createTextNode( css ) );
					}

					document.head.appendChild( style );
					proximaContainer.current.appendChild( style );
				};


				createCss();

				showSplash();


				app.on( 'preload:end', function () {
					app.off( 'preload:progress' );
				} );
				app.on( 'preload:progress', setProgress );
				app.on( 'start', hideSplash );

				connectWebGLEvents( app );

			} );
		}
	}, [proxima, proximaContainer] );


	const connectWebGLEvents = pcApp => {
		pcApp.on( "api:onUnitSelected", ( unitId ) => {
			setSelectedUnitId( unitId );
		} );

		pcApp.on( "api:onHotspotClicked", ( hotspotId ) => {
			openGalleryPhoto( hotspotId );
		} )

		pcApp.fire("api:onTestWebEvent");
		pcApp.on("testWebCommuncationButton:testWebEvent", () => console.log("test has been fired"))
	};

	useEffect( () => {
		if ( proxima.current && proxima.current.canvas ) {
			proximaContainer.current.appendChild( proxima.current.canvas );
			connectWebGLEvents( proxima.current.app );
		}
	} )

	let galleryPhotos = [
		{
			src: 'https://storage.googleapis.com/proxima-uploads-project2-dev/Kindred_Co_Work_2k_58d9c39390/Kindred_Co_Work_2k_58d9c39390.jpg?70728468.1',
			width: 2000,
			height: 1250,
			webglImageSlug: 'CO-WORKINGSPACE',
			title: 'Co-Working Space',
		},
		{
			src: 'https://storage.googleapis.com/proxima-uploads-project2-dev/Kindred_Lobby_2k_6025ad51eb/Kindred_Lobby_2k_6025ad51eb.jpg?71471039.9',
			width: 2000,
			height: 1250,
			webglImageSlug: 'LOBBY',
			title: 'Lobby',
		},
		{
			src: 'https://storage.googleapis.com/proxima-uploads-project2-dev/Kindred_Gym_2k_7b112c3c9f/Kindred_Gym_2k_7b112c3c9f.jpg?10968097.899999976',
			width: 2000,
			height: 1250,
			webglImageSlug: 'FITNESSCENTRE&YOGASTUDIO',
			title: 'Fitness Centre & Yoga Studio',
		},
		{
			src: 'https://storage.googleapis.com/proxima-uploads-project2-dev/Kindred_Terrace_2k_ffe07c4008/Kindred_Terrace_2k_ffe07c4008.jpg?11129363.300000012',
			width: 2000,
			height: 1250,
			webglImageSlug: '8thFLOORTERRACE',
			title: '8th Floor Terrace',
		},
		{
			src: 'https://storage.googleapis.com/proxima-uploads-project2-dev/Kindred_Entrance_2k_b0a5874599/Kindred_Entrance_2k_b0a5874599.jpg?11167772.399999976',
			width: 2000,
			height: 1250,
			webglImageSlug: 'ERINMILLSPARKWAYENTRANCE',
			title: 'Erin Mills Parkway Entrance',
		},
		{
			src: 'https://storage.googleapis.com/proxima-uploads-project2-dev/Kindred_Party_2k_d10812e08a/Kindred_Party_2k_d10812e08a.jpg?11346179.600000024',
			width: 2000,
			height: 1250,
			webglImageSlug: '2ndFLOORPARTYROOM',
			title: '2nd Floor Party Room',
		},
		{
			src: 'https://storage.googleapis.com/proxima-uploads-project2-dev/Kindred_Games_2k_a497eee96e/Kindred_Games_2k_a497eee96e.jpg?11664965.199999988',
			width: 2000,
			height: 1250,
			webglImageSlug: '8thFLOORGAMESROOM',
			title: '8th Floor Games Room',
		},
		{
			src: 'https://storage.googleapis.com/proxima-uploads-project2-dev/Kindred_Lounge_2k_955a87f2db/Kindred_Lounge_2k_955a87f2db.jpg?11700877.300000012',
			width: 2000,
			height: 1250,
			webglImageSlug: '8thFLOORLOUNGE',
			title: '8th Floor Lounge',
		},
	]


	const [currentImage, setCurrentImage] = useState( 0 );
	const [isViewerOpen, setIsViewerOpen] = useState( false );

	const openLightbox = useCallback( ( e, { photo, index } ) => {
		setCurrentImage( index );
		setIsViewerOpen( true );
	} );

	const closeLightbox = () => {
		setCurrentImage( 0 );
		setIsViewerOpen( false );
	};


	const openGalleryPhoto = ( hotspotId ) => {
		const matchingImgIdx = galleryPhotos.findIndex( galleryPhoto => galleryPhoto.webglImageSlug === hotspotId );
		if ( matchingImgIdx !== -1 ) {
			openLightbox( null, {
				photo: galleryPhotos[matchingImgIdx],
				index: matchingImgIdx,
			} );
		}
	};



	// Process window events from WebGL
	useEffect( () => {
		const handleHotspotClick = ( e ) => {
			if ( e.origin === window.location.origin &&
				e.data &&
				e.data.message &&
				e.data.message === ConstantData.webglWindowMessageTypes.webglHotspotClicked ) {
				openGalleryPhoto( e.data.renderId );
			}
		};

		window.addEventListener( "message", handleHotspotClick );
		return () => window.removeEventListener( "message", handleHotspotClick );
	} );



	// Send events to WebGL
	useEffect( () => {
		proxima.current.app.fire( 'highlightByUnitId', selectedUnitId );
	}, [selectedUnitId, proxima] )

	const {
		isUnitCardSlideoutOpen,
		toggleIsUnitCardSlideoutOpen: toggleUnitCardSlideoutPane,
	} = useOpenClosePane( 'UnitCardSlideout' );

	const {
		isVirtualTourOpen,
		setIsVirtualTourOpenToClosed,
		toggleIsVirtualTourOpen,
	} = useOpenClosePane( "VirtualTour" )

	const {
		isViewStudySlideUpOpen,
		setIsViewStudySlideUpOpenToClosed,
		toggleIsViewStudySlideUpOpen,
	} = useOpenClosePane( "ViewStudySlideUp" )
	
	const [doShowFilter, setDoShowFilter] = useState( false );
	const [modelType, setModelType] = useState('3D Model');
	const [currViewType, setModleView] = useState(ConstantData.webglViewTypes.building3DModel)
	const [hover, toggleOnHover] = useState(false);
	const unitDetailOpen = props.isUnitDetailOpen;
	const isSavedHomesCompareOpen = props.isSavedHomesCompareOpen;
	const isSavedHomesSlideoutOpen= props.isSavedHomesSlideoutOpen;

	const classes = useStyles({doShowFilter, unitDetailOpen, isUnitCardSlideoutOpen, isSavedHomesCompareOpen, isSavedHomesSlideoutOpen});

	const { loading, error, data } = useQuery( QueryData.unitByCustomId, {
		variables: {
			customUnitId: selectedUnitId
		}
	} );

	let unit = null;
	if ( loading ) unit = null;
	if ( error ) unit = null;
	if ( data && data.units && data.units.length > 0 ) {
		unit = data.units[0];
	};

	const viewStudyUrl = useQuery( QueryData.siteConfiguration);

	const { unitCustomIdsFiltered } = useGlobalUnitsFilterResult();

	useEffect( () => {
		if ( selectedUnitId !== -1 && unitCustomIdsFiltered.length === 0 )
			return proxima.current.app.fire( 'highlightByUnitId', selectedUnitId );
		if( selectedUnitId !== -1 && unitCustomIdsFiltered.length !== 0 )
			return proxima.current.app.fire( 'highlightByUnitIdMany', [...unitCustomIdsFiltered, selectedUnitId] )
		else
			proxima.current.app.fire( 'highlightByUnitIdMany', unitCustomIdsFiltered )
	}, [proxima, selectedUnitId, unitCustomIdsFiltered] )

	const toggleUnitDetailPane = () => {
		return props.setUnitDetailOpen(!props.isUnitDetailOpen)
	};	

	function setViewType( viewType ) {
		let webglViewArgument;
		switch ( viewType ) {
			case ConstantData.webglViewTypes.byFloorplateDetailed:
				case ConstantData.webglViewTypes.byFloorplateSimple:
				setModelType('2D Floorplate');
				setModleView(viewType)
				webglViewArgument = 1;
				break;
			case ConstantData.webglViewTypes.buliding3DModel:
			default:
				setModelType('3D Model');
				setModleView(viewType)
				webglViewArgument = -1;
				break;
		}
		if ( window.pc )
			window.pc.Application.getApplication().floorPlanManager.setFloorPlanEnabled( webglViewArgument );
	}

	const defaultTransition = {
		type: "tween",
		// bounce: .25,
		duration: .5,
	};

	return (
		<>
		{props.isProximaLoaded && 
			<FilterModal 
				doShowFilter={doShowFilter}
				setDoShowFilter={setDoShowFilter} 
				modelType={modelType}
			/>
		}
		<Layout 
			isVirtualTourOpen={isVirtualTourOpen}
			setIsVirtualTourOpenToClosed={setIsVirtualTourOpenToClosed}
			isViewStudySlideUpOpen={isViewStudySlideUpOpen} 
			setIsViewStudySlideUpOpenToClosed={setIsViewStudySlideUpOpenToClosed}
			selectedUnitData={data} 
			isUnitDetailOpen={props.isUnitDetailOpen} 
			setUnitDetailOpen={props.setUnitDetailOpen} 
			toggleIsSavedHomesSlideoutOpen={props.toggleIsSavedHomesSlideoutOpen}
			isSavedHomesSlideoutOpen={props.isSavedHomesSlideoutOpen}
			toggleIsSavedHomesCompareOpen={props.toggleIsSavedHomesCompareOpen} 
			isSavedHomesCompareOpen={props.isSavedHomesCompareOpen}
			toggleIsRegisterOpen={props.toggleIsRegisterOpen}
			footerHidden
		>
			{props.isProximaLoaded && 
				<FilterHeader 
					doShowFilter={doShowFilter} 
					setDoShowFilter={setDoShowFilter} 
					modelType={modelType}
				/>
			}
			<div className={classes.slideAndCanvasContainer}>
			{props.isProximaLoaded && <UnitCardSlideout
					unitId={selectedUnitId}
					isOpen={isUnitCardSlideoutOpen}
					toggleIsOpen={() => toggleUnitCardSlideoutPane()}
					toggleUnitDetailPane={() => toggleUnitDetailPane()}
					cardOnClickLeftButton={() => setIsUnitDetailOpen( true )}
					toggleIsViewStudySlideUpOpenFcn={toggleIsViewStudySlideUpOpen}
					selectedUnitId={selectedUnitId}
					setSelectedUnitId={setSelectedUnitId}

					toggleIsVirtualTourOpen={toggleIsVirtualTourOpen}
				/>}
				
				<div className={classes.emptyWrapper} />
				<div className={classes.canvasElementsWrapper}>
				<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%'}}>
				{props.isProximaLoaded && <div className={classes.top}>
					<motion.div
						className={classes.closeX}
						onClick={() => toggleUnitCardSlideoutPane()}
						id="unit-card-slideout-toggle"
						animate={isUnitCardSlideoutOpen ? "open" : "closed"}
						transition={defaultTransition}
					>
						<motion.div
							className={classes.openIcon}

							animate={isUnitCardSlideoutOpen ? "open" : "closed"}
							variants={{
								open: {
									rotate: 0,
								},
								closed: {
									rotate: 180,
								},
							}}
							transition={defaultTransition}
						>
							<ChevronRighMedium fill={ThemeData.colours.primaryAccent} />
						</motion.div>
						<motion.div
							className={classes.openText}
						>
							{ !isUnitCardSlideoutOpen && 'Show Plans'}
						</motion.div>
					</motion.div>
				</div>}
				<ButtonOutline
						label={modelType}
						className={classes.modelViewSwitch}
						id="model-switch-view"
						padding={20}
						textColour={ThemeData.colours.white}
						hoverTextColour={ThemeData.colours.primaryBg}
						hoverBackgroundColour={ThemeData.colours.primaryAccent}
						border="none"
						isRounded
						backgroundColour={ThemeData.colours.primaryHeaderBg}
						popupWindow={
							<div  className={classes.modelViewSwitchButtons}>
								<div>
									{
										currViewType === ConstantData.webglViewTypes.building3DModel ? 
										<FullModelIconActive 
											className={classes.iconModelSelect3D} 
											fill={ThemeData.colours.primaryAccent}
											stroke={ThemeData.colours.primaryAccent} 
											onClick={(() => setViewType(ConstantData.webglViewTypes.building3DModel))} 
										/> :
										<FullModelIcon 
											className={classes.iconModelSelect3D}
											fill={hover ? ThemeData.colours.primaryAccentFade : 'none'}
											stroke={hover ? ThemeData.colours.primaryAccent : ThemeData.colours.neuDarkGreen} 
											onClick={() => {setViewType(ConstantData.webglViewTypes.building3DModel); toggleOnHover(false);}} 
											onMouseOver={() => toggleOnHover(true)}
											onMouseLeave={() => toggleOnHover(false)}
										/>
									}
									<div>3D Model</div>
								</div>
								<div id="2d-floorplate-switch">
									{
										currViewType === ConstantData.webglViewTypes.byFloorplateSimple ?
										<FloorplateModelIconActive 
											className={classes.iconModelSelect2D}
											fill={ThemeData.colours.primaryAccent}
											stroke={ThemeData.colours.primaryAccent} 
											onClick={(() => setViewType(ConstantData.webglViewTypes.byFloorplateSimple))}
											
										/> :
										<FloorplateModelIcon 
											className={classes.iconModelSelect2D}
											fill={hover ? ThemeData.colours.primaryAccentFade : 'none'}
											stroke={hover ? ThemeData.colours.primaryAccent : ThemeData.colours.neuDarkGreen}  
											onClick={() => {setViewType(ConstantData.webglViewTypes.byFloorplateSimple); toggleOnHover(false);}} 
											onMouseOver={() => toggleOnHover(true)}
											onMouseLeave={() => toggleOnHover(false)}
										/>
									}
									<div>2D Floorplate</div>
								</div>
							</div>
							}
							
							>
						&nbsp;<FaCaretDown />
					</ButtonOutline>
					<div style={{width: isUnitCardSlideoutOpen ? 85 : 215}}></div>
					</div>
					<motion.div
						ref={proximaContainer}
						className={classes.canvasWrapper}
						id="canvasWrapper"

						animate={isUnitCardSlideoutOpen ? "partialOpen" : "fullOpen"}
						transition={{
							type: 'tween',
							duration: .5,
						}}
					/>
					{/* {TODO: replace with strapi location: currently using hardcoded json file} */}
					{/* <p>{hooks.disclaimerData.data.contactInfo.disclaimer_2}</p> */}								
					<p className={classes.disclaimer}>{ConstantData.disclaimerData.disclaimer_4}</p>

				</div>
			</div>
			{!isUnitCardSlideoutOpen && 
			<>
				<UnitDetailCard
					selectedUnitId={selectedUnitId}
					unit={unit}
					toggleUnitDetailPaneFcn={() => toggleUnitDetailPane()}
					toggleViewStudyPaneFcn={toggleIsViewStudySlideUpOpen}
					toggleVirtualTourPaneFcn={toggleIsVirtualTourOpen}
				/>
				{unit !== null &&
					<div className={classes.keyplan}>
						<img
							className={classes.keyplanImg}
							src={NetData.imgUri( unit && unit.unit_plan_type.keyplan_image && unit.unit_plan_type.keyplan_image.url )}
							alt={unit && unit.unit_plan_type.keyplan_image && unit.unit_plan_type.keyplan_image.alternativeText} />
					</div>
				}
			</>
			}
			<ViewStudy
				isOpen={isViewStudySlideUpOpen}
				toggleViewStudyFcn={toggleIsViewStudySlideUpOpen}
				viewUrl={viewStudyUrl.data && viewStudyUrl.data.siteConfiguration.view_study_iframe_url}
			/>
			<VirtualTour
				isOpen={isVirtualTourOpen}
				src={siteConfiguration.data && siteConfiguration.data.virtual_tour_iframe_url}
				toggleVirtualTourFcn={() => toggleIsVirtualTourOpen()}
				doUseHeader={false}
			/>
			<ModalGateway>
				{isViewerOpen ? (
					<Modal onClose={closeLightbox}>
						<Carousel
							currentIndex={currentImage}
							views={
								galleryPhotos.map( x => ( {
									...x,
									key: x.src,
									srcset: x.srcset,
									caption: x.title,
								} ) )
							}
						/>
					</Modal>
				) : null}
			</ModalGateway>
		</Layout>
		</>
	);
}



export default Proximation;