import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import 'index.css';
import reportWebVitals from 'reportWebVitals';

import {
	ApolloClient,
	HttpLink,
	InMemoryCache,
	ApolloProvider,
} from '@apollo/client';
import fetch from 'cross-fetch';
import Root from "Root";
import ReactGA from 'react-ga4';

const gqlUri = `${process.env.REACT_APP_BACKEND_URI}/graphql`;
const client = new ApolloClient( {
	uri: gqlUri,
	cache: new InMemoryCache(),
	link: new HttpLink({ uri: gqlUri, fetch }),
} );

const GOOGLE_TRACKING_ID = process.env.REACT_APP_GOOGLE_TRACKING_ID;
ReactGA.initialize(GOOGLE_TRACKING_ID);

const appHeight = () => {
	const doc = document.documentElement;
	doc.style.setProperty(` --app-height`, `${window.innerHeight}px`)
}
window.addEventListener('resize', appHeight)
appHeight();
const rootElement = document.getElementById( 'root' );

ReactDOM.render(
		<>
			<Router>
				<ApolloProvider client={client}>
					<Root />
				</ApolloProvider>
			</Router>
		</>,
		rootElement );

reportWebVitals();
