import React, {
} from "react";
import { useLocation } from "react-router-dom";

import {
	createUseStyles
} from "react-jss";

import {
	motion,
} from "framer-motion";


import useSavedHomes from "hooks/useSavedHomes";

import ButtonGroup from "./ButtonGroup";
import ButtonOutline from "./ButtonOutline";
import UnitInfoPlanNameTitle from "components/UnitInfoPlanNameTitle";
import UnitInfoLine from "./UnitInfoLine";

import { sendGoogleAnalyticsEvent } from "util/sendGoogleAnalyticsEvent";

import ThemeData from "../data/ThemeData";
import NetData from "../data/NetData";
import { BookMarkButton } from "./BookMarkButton";


const useStyles = createUseStyles(
	{
		wrapper: {
			position: 'relative',
			paddingTop: 5,
			boxSizing: 'border-box',
			...ThemeData.styles.hoverCard,
			// border: `5px rgba( 1, 1, 1, 0 ) solid`,
		},
		selectedUnitHighlight: {
			border: `2px ${ThemeData.colours.primaryAccent} solid`,
			// boxShadow: `inset 0 0 0 5px ${ThemeData.colours.primaryAccent}`,
		},
		hidden: {
			display: 'none',
		},
		floorplanImage: {
			minHeight: 190,
			borderBottom: `1px ${ThemeData.colours.blackBg15} solid`,
		},
		'$floorplanImage, $details': {
			borderBottom: `1px ${ThemeData.colours.mainBorder} solid`,
		},
		details: {
			display: 'flex',
			justifyContent: 'space-between',
			position: 'relative',
			minHeight: 63,
			padding: '20px 20px 5px 20px',
			textAlign: 'left',
			'& p': {
			},
		},
		unitInfoPlanNameTitle: {
			margin: 0,
			padding: 0,
			color: ThemeData.colours.secondaryFont,
			fontFamily: ThemeData.fonts.title,
			fontSize: '16px',
		},
		unitInfoLine: {
			marginTop: 10,
			color: ThemeData.colours.grey,
			fontFamily: ThemeData.fonts.titleSub,
			fontSize: '12px',
			letterSpacing: '0.05em',
		},
		actionButton: {
			position: 'absolute',
			top: 16,
			right: 20,
			fontSize: 33,
			lineHeight: '32px',
		},
		buttonArea: {
			paddingInline: 20,
			paddingBlockEnd: 20,
		},
		moreDetailsButtonArea: {
			borderTop: `1px ${ThemeData.colours.blackBg15} solid`,
		},
	},
	{
		name: "UnitDetailCardGeneric",
	}
)

function renderUnitDetailCardGeneric( dataUnit, classes, error, toggleUnitDetailPaneFcn, hooks, location, props ) {
	const doDisplayCard = true; 
	const apiUri = NetData.apiUri;

	return (
		<motion.div
			className={
				`${props.className
				} ${classes.wrapper
				} ${doDisplayCard ? "" : classes.hidden
				} ${dataUnit.custom_id === props.selectedUnitId && props.isToAllowSelection ? classes.selectedUnitHighlight : ""
				}`}
			onClick={() =>
				props.setSelectedUnitId( dataUnit.custom_id )
			}
		>
			<div className={classes.floorplanImage}>
				{dataUnit && dataUnit.unit_plan_type && dataUnit.unit_plan_type.floorplan_image &&
					<img
						src={`${NetData.imgUri( dataUnit.unit_plan_type.floorplan_image.formats && dataUnit.unit_plan_type.floorplan_image.formats.small ?
							dataUnit.unit_plan_type.floorplan_image.formats.small.url :
							dataUnit.unit_plan_type.floorplan_image.url )} `}
						alt={dataUnit.unit_plan_type.floorplan_image.alternativeText}
						width={300}
						height={187}
					/>
				}
				{( !dataUnit || !dataUnit.unit_plan_type || !dataUnit.unit_plan_type.floorplan_image ) &&
					<img
						src={`${apiUri}/uploads/floorplan_placeholder_85dd01fc30.png`}
						alt='floorplan placeholder'
						width={331}
						height={187}
					/>
				}
			</div>
			<div className={classes.details}>
				<div>
					{props.unitCardActionButton}
					<UnitInfoPlanNameTitle
						data={dataUnit}
						fontSize={36}
						doShowUnitNumber={true}
						className={classes.unitInfoPlanNameTitle}
					/>
					<div className={classes.unitInfoLine}>{dataUnit && <UnitInfoLine
						data={dataUnit}
					/>}</div>
				</div>
			<BookMarkButton hooks={hooks} dataUnit={dataUnit} height={30} width={15} />
			</div>
			<div className={classes.buttonArea}>
				<ButtonGroup
					justifyContent='center'
				>
					<ButtonOutline
						id="enjoy-the-views"
						label="Enjoy the View"
						textColour={ThemeData.colours.primaryAccent}
						hoverTextColour={ThemeData.colours.white}
						borderColour={ThemeData.colours.grey25}
						to="."
						onClick={props.toggleIsViewStudySlideUpOpenFcn}
						firstInColl
					/>
					<ButtonOutline
						id="take-the-tour"
						label="Take the Tour"
						textColour={ThemeData.colours.primaryAccent}
						hoverTextColour={ThemeData.colours.white}
						borderColour={ThemeData.colours.grey25}
						onClick={() => props.toggleVirtualTourPaneFcn()}
						to="."
					/>
				</ButtonGroup>
			</div>
			<div className={classes.moreDetailsButtonArea}>
				<ButtonOutline
					id={`more-details-button-unit-card-${dataUnit.id}`}
					label="More Details"
					padding={20}
					textColour={ThemeData.colours.primaryAccent}
					hoverTextColour={ThemeData.colours.white}
					border="none"
					backgroundColour={ThemeData.colours.neuGray}
					onClick={() => {
							toggleUnitDetailPaneFcn();
							dataUnit.unit_plan_type && sendGoogleAnalyticsEvent(
								`Floor Plan View - ${location.pathname}`,
								`User Clicks ${dataUnit.unit_plan_type.data.attributes.name} Unit ${dataUnit.unit_number}`,
								`${dataUnit.unit_plan_type.data.attributes.name} Unit ${dataUnit.unit_number}`,
								`${dataUnit.unit_plan_type.data.attributes.name} Unit ${dataUnit.unit_number}`
							);
						}
					}
				/>
			</div>
		</motion.div>
	);
}

function UnitDetailCardGeneric( { dataUnit, toggleUnitDetailPaneFcn, ...props } ) {
	const classes = useStyles();
	const location = useLocation();
	const { savedHomes, toggleSavedHome, savedHomesIncludes } = useSavedHomes();

	let hooks = {
		savedHomes,
		toggleSavedHome,
		savedHomesIncludes
	}

	return renderUnitDetailCardGeneric( dataUnit, classes, null, toggleUnitDetailPaneFcn, hooks, location, props );
}



UnitDetailCardGeneric.defaultProps = {
	isToAllowSelection: false,
}

export default UnitDetailCardGeneric;