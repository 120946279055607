import React, {
	// useState,
	// useRef,
} from "react";

// import {
// 	useQuery,
// 	gql,
// } from "@apollo/client";

import {
	createUseStyles,
} from "react-jss";

import {
	motion,
} from "framer-motion";


import HeaderSimple from "./HeaderSimple";


import ThemeData from "../data/ThemeData";
// import NetData from "../data/NetData";


import navArrowLeftDark from "../img/nav_arrow_left_dark.svg";



const useStyles = createUseStyles(
	{
		wrapper: props => ( {
			position: 'fixed',
			top: props.doUseHeader ? 0 : '100px',
			left: 0,
			right: 0,

			width: '100%',
			// height: '100%',
			height: 'calc( 100vh - 100px )',


		} ),
		header: {
			position: 'absolute',
			top: 0,
			left: 0,

			width: '100%',
			height: 100,

			// backgroundColor: ThemeData.colours.eggshellTextBg,
			backgroundColor: ThemeData.colours.primaryHeaderBg,

			zIndex: 2,
		},
		backButton: {
			width: 100,
			height: 100,

			cursor: 'pointer',

			color: '#FFF',

			background: 'no-repeat center',
			backgroundImage: `url( ${navArrowLeftDark} )`,
		},
		virtualTour: {
			width: '100%',
			height: '100%',
			// paddingTop: 100,

			backgroundColor: ThemeData.colours.white,
		},
	},
	{
		name: "VirtualTour",
	}
)



const wrapperAnimVariants = {
	open: {
		y: 0,
		zIndex: 9999,
		display: 'block',
	},
	closed: {
		y: "100vh",
		display: 'block',
		transitionEnd: {
			zIndex: -2,
			display: 'none'
		},
	},
}

function RenderVirtualTour( classes, error, isOpen, toggleVirtualTourFcn, src, props ) {

	// console.log( 'ViewStudy isOpen', isOpen );

	// const apiUri = NetData.apiUri;


	return (
		<motion.div
			id="take-the-tour"
			className={classes.wrapper}
			initial={false}
			animate={isOpen ? "open" : "closed"}
			variants={wrapperAnimVariants}
			transition={{
				type: 'linear',
				duration: .5,
			}}
		>
			{props.doUseHeader &&
				<HeaderSimple
					backgroundColor={ThemeData.colours.primaryHeaderBg}
					onBackClick={() => toggleVirtualTourFcn()} />
			}
			<div className={classes.virtualTour}>
				{/* <div> Pitch: {pitch} </div>
				<div> Yaw: {yaw} </div> */}
				<iframe
					title="Virtual Tour"
					width="100%"
					height="100%"
					frameBorder="0"
					src={src}
				/>
			</div>
		</motion.div>
	);
}



const VirtualTour = ( { isOpen, toggleVirtualTourFcn, src, ...props } ) => {

	const classes = useStyles( props );

	return RenderVirtualTour( classes, null, isOpen, toggleVirtualTourFcn, src, props );

};


VirtualTour.defaultProps = {
	doUseHeader: false,
}



export default VirtualTour;