import { ConstantData } from './ConstantData';

export const filterableSources = {
	rooms:{
		label: 'Rooms',
		filterType: ConstantData.unitFilterTypes.checkedList,
		unitRelationDataType: 'unitHomeTypes',
		dataListItemLabelField: 'name'
	},
	floors: {
		label: 'Floors',
		filterType: ConstantData.unitFilterTypes.checkedList,
		unitRelationDataType: 'unitFloors',
		dataListItemLabelField: 'name'
	},
	orientation: {
		label: 'Orientation',
		filterType: ConstantData.unitFilterTypes.checkedList,
		unitRelationDataType: 'unitExposures',
		dataListItemLabelField: 'direction',
	},
	buildings: {
		label: 'Building',
		filterType: ConstantData.unitFilterTypes.checkedList,
		unitRelationDataType: 'unitBuildings',
		dataListItemLabelField: 'name',
	},
	price: {
		label: 'Price',
		filterType: ConstantData.unitFilterTypes.range,
		unitField: 'price',
		rangeMinimumValue: 800000,
		rangeMaximumValue: 2500000,
		rangeStep: 100000,
		displayedDataFormattingFunction: ( value ) => {
			return `$${value / 1000}K`
		},
	},
	size: {
		label: 'Size',
		filterType: ConstantData.unitFilterTypes.range,
		unitField: 'size',
		rangeMinimumValue: 1340,
		rangeMaximumValue: 1640,
		rangeStep: 10,
		displayedDataFormattingFunction: ( value ) => {
			return `${value} sqft`
		},
	},
	availability: {
		label: 'Availability',
		filterType: ConstantData.unitFilterTypes.checkedList,
		unitRelationDataType: 'unitAvailabilityStates',
		dataListItemLabelField: 'name',
	},
};

export const filterableSourcesHeaderButtons = [
    {
        label: 'Availability',
        filterType: ConstantData.unitFilterTypes.checkedList,
        unitRelationDataType: 'unitAvailabilityStates',
        dataListItemLabelField: 'name',
    },
    {
        label: 'Price',
        filterType: ConstantData.unitFilterTypes.range,
        unitField: 'price',
        unitRelationDataType: "price",
        rangeMinimumValue: 800000,
        rangeMaximumValue: 2500000,
        rangeStep: 100000,
        displayedDataFormattingFunction: ( value ) => {
            return `$${value / 1000}K`
        },
    },
	{
		label: 'Rooms',
		filterType: ConstantData.unitFilterTypes.checkedList,
		unitRelationDataType: 'unitHomeTypes',
		dataListItemLabelField: 'name',
	},
    {
        label: 'Size',
        unitRelationDataType: "size",
        filterType: ConstantData.unitFilterTypes.range,
        unitField: 'size',
        rangeMinimumValue: 1340,
        rangeMaximumValue: 1640,
        rangeStep: 10,
        displayedDataFormattingFunction: ( value ) => {
            return `${value} sqft`
        },
    },
    {
        label: 'Orientation',
        filterType: ConstantData.unitFilterTypes.checkedList,
        unitRelationDataType: 'unitExposures',
        dataListItemLabelField: 'name'
    },
	{
		label: 'Building',
		filterType: ConstantData.unitFilterTypes.checkedList,
		unitRelationDataType: 'unitBuildings',
		dataListItemLabelField: 'name',
	},
];