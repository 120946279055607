import React from "react";
import {
	createUseStyles,
} from "react-jss";

import List from '@mui/material/List';
import ThemeData from "data/ThemeData";
import FilterButton from "./FilterButton";
import ButtonOutline from "./ButtonOutline";

const useStyles = createUseStyles(
	{
		wrapper: {
			textAlign: 'center',
			backgroundColor: ThemeData.colours.white,
			maxWidth: 310
		},
		list: {
			color: ThemeData.colours.secondaryFont,
			backgroundColor: ThemeData.colours.white,
			display: 'flex',
			flexDirection: 'row',
			flexWrap: 'wrap',
			justifyContent: 'center',
		},
		filterItem: {
			border: '1px solid #EEEDE6',
			borderRadius: 4,
			width: 128,
			height: 47,
			textAlign: 'center',
			margin: 7,
			cursor: 'pointer',
			'&:hover':{
				backgroundColor: ThemeData.colours.primaryAccentFade,
				color: ThemeData.colours.primaryAccent
			}
		},
		filterActivated: {
			backgroundColor: ThemeData.colours.primaryAccentFade,
			color: ThemeData.colours.primaryAccent
		},
	},
	{
		name: "InputCheckedList",
	}
)

const InputCheckedList = ( { data, className, ...props } ) => {

	const classes = useStyles();

	const clearFilter = (filterType, itemId) => {      
        const newFilterSettings = { ...props.filterSettings };
        if ( !newFilterSettings.hasOwnProperty( filterType ))
            newFilterSettings[filterType] = {};
        if( !newFilterSettings[filterType].hasOwnProperty( itemId ) )
            newFilterSettings[filterType][itemId] = {
                    doFilterByOr: false,
                    id: itemId
            };
        else
            newFilterSettings[props.filterType][itemId].doFilterByOr = false;
    
            props.hooks.globalUnitsFilter.setFilter( newFilterSettings );
    }

	return (
		<div className={`${classes.wrapper} ${className}`}>
			<List
				className={classes.list}
				sx={{
					width: '100%',
				}}
			>
				{data.map( ( listItemData ) => {
					return (
						<FilterButton
							classes={classes}
							name={listItemData.name || listItemData.direction}
							filterObject={listItemData}
							data={data}
							hooks={props.hooks}
							listItemId={listItemData.id}
							filterType={props.filterType}
							filterSettings={props.filterSettings}
							modelType={props.modelType}
						/>
					);
				} )}
			</List>
			<div className={classes.moreDetailsButtonArea}>
				<ButtonOutline
					label="Clear"
					padding={20}
					textColour={ThemeData.colours.primaryAccent}
					hoverTextColour={ThemeData.colours.white}
					border="none"
					backgroundColour={ThemeData.colours.clay05}
					onClick={() => {
						data.map( ( listItemData ) => 
							clearFilter(props.filterType, listItemData.id)
						)
					}}
				/>
			</div>
		</div>
	);
}



export default InputCheckedList;